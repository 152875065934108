import Store from "../js/store";
import HomePage from '../pages/home.vue';
import LoginPage from '../pages/login.vue';
import AboutPage from '../pages/about.vue';
import tablepage from '../pages/table.vue';
import x from '../pages/x.vue';
import RegisterUSer from '../pages/register-user.vue';
import RegisterShop from '../pages/register-shop.vue';
import RegisterChoose from '../pages/register-choose';
import DynamicRoutePage from '../pages/dynamic-route.vue';
import RequestAndLoad from '../pages/request-and-load.vue';
import NotFoundPage from '../pages/404.vue';
import Test from '../pages/test.vue';
import MenuChoose from '../pages/menu-choose-tb.vue';
// import MenuChoose from '../pages/menu-choose-tbcopy.vue';
// import MenuChoose from '../pages/menu-choose.vue';
import loginScreen from "framework7-vue/components/login-screen";
import AdminTablePage from '../pages/admintable.vue';
import orders from '../pages/oders.vue';
import menucheck from '../pages/menu-check.vue';
import checkbill from '../pages/checkbill.vue';
import managepage from '../pages/manage-list.vue';
import itemAll from '../pages/item-all.vue';
import newItem from '../pages/new-item.vue';
import CategoryPage from '../pages/category.vue';
import billAll from '../pages/bill-all.vue';
import billDetail from '../pages/bill-detail.vue';
import settingList from '../pages/setting-list.vue';
import currencySetting from '../pages/currency.vue';
import settingUser from '../pages/setting-profile-user.vue';
import settingShop from '../pages/setting-profile-shop.vue';
import report from '../pages/report.vue';
import reportSaleall from '../pages/report-allsale.vue';
import forgetPassword from '../pages/forget-password.vue';
import sale from '../pages/Sale.vue';
import reportbestsale from '../pages/bestsale.vue';
import packagepage from '../pages/package.vue';
import qrscan from '../pages/QRcodes-scan.vue';
import discountlist from '../pages/discount.vue';
import addDiscount from '../pages/new-discount.vue';
import newPrinter from '../pages/new-printer.vue';
import printerList from '../pages/printer-list.vue';
import printerlabel from '../pages/printerlabel.vue';
const checkAuth = ({ path, component }) => {
  return {
    path,
    async(to, from, resolve, reject) {
      if (!Store.getters["LoginSystem/isLoggedIn"]) {//
        resolve({
          component:
            // newItem 
            tablepage
        });
      } else {
        resolve({ component: component });
      }
    },
  };
};

var routes = [
  checkAuth({
    path: "/",
    component: LoginPage,
  }),
  // {
  //   path: "/",
  //   component: LoginPage,
  // },
  {
    path: '/report/',
    component: report,
  },
  {
    path: '/login/',
    component: LoginPage,
  },
  {
    path: '/home/',
    component: HomePage,
  },
  {
    path: '/about/',
    component: AboutPage,
  },
  {
    path: '/table/',
    component: tablepage,
  },
  {
    path: '/registerUser/',
    component: RegisterUSer,
  },
  {
    path: '/registerShop/',
    component: RegisterShop,
  },
  {
    path: '/registerchoose/',
    component: RegisterChoose,
  },
  {
    path: '/registerShop/',
    component: RegisterShop,
  },
  {
    path: '/menuchoose/:id',
    component: MenuChoose,
  },
  {
    path: '/menucheck/:tid',
    component: menucheck,
  }, {
    path: '/orders/:tid',
    component: orders,
  },
  {
    path: '/checkbill/:tid',
    component: checkbill,
  },
  {
    path: '/settinglist/',
    component: settingList,
  },
  {
    path: '/allItem/',
    component: itemAll,
  },
  {
    path: '/billAll/',
    component: billAll,
  },
  {
    path: '/AdminTable/',
    component: AdminTablePage,
  },

  {
    path: '/manageList/',
    component: managepage,
  },
  {
    path: '/category/',
    component: CategoryPage,
  },

  {
    path: '/addItem/:id',
    component: newItem,
  },
  {
    path: '/forgetpass/',
    component: forgetPassword,
  },
  {
    path: '/editProfile/',
    component: settingUser,
  },
  {
    path: '/editShop/',
    component: settingShop,
  },
  {
    path: '/editCurrency/',
    component: currencySetting,
  },
  {
    path: '/billdetail/:tsid',
    component: billDetail,
  },
  {
    path: '/sale/',
    component: sale,
  },
  {
    path: '/reportSaleall/',
    component: reportSaleall,
  },
  {
    path: '/reportbestsale/',
    component: reportbestsale,
  },
  {
    path: '/package/',
    component: packagepage,
  },
  {
    path: '/printerlabel/',
    component: printerlabel,
  },
  {
    path: '/qrcode/',
    component: qrscan,
  },
  {
    path: '/discountList/',
    component: discountlist,
  },
  {
    path: '/newDiscount/',
    component: addDiscount,
  },

  {
    path: '/ListPrinter/',
    component: printerList,
  },
  {
    path: '/addPrinter/',
    component: newPrinter,
  },

  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
