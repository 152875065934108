<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar large :sliding="false">
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title sliding>Mumu POS</f7-nav-title>
      <f7-nav-right>
        <f7-link icon-ios="f7:lock_open_fill" icon-aurora="f7:lock_open_fill" icon-md="material:lock_open_fill" panel-open="right"></f7-link>
      </f7-nav-right>
      <f7-nav-title-large>Mumu POS</f7-nav-title-large>
    </f7-navbar>
    <!-- Toolbar-->
    <f7-toolbar bottom>
      <f7-link>Left Link</f7-link>
      <f7-link>Right Link</f7-link>
    </f7-toolbar>
    <!-- Page content-->
    <f7-block strong>
      <p>Here is your blank Framework7 app. Let's see what we have here.</p>
    </f7-block>
    <f7-block-title>Navigation</f7-block-title>
    <f7-list>
      <f7-list-item link="/about/" title="About"></f7-list-item>
      <f7-list-item link="/form/" title="Form"></f7-list-item>
    </f7-list>

    <f7-block-title>Modals</f7-block-title>
    <f7-block strong>
      <f7-row>
        <f7-col width="50">
          <f7-button fill raised popup-open="#my-popup">Popup</f7-button>
        </f7-col>
        <f7-col width="50">
          <f7-button fill raised login-screen-open="#my-login-screen">Login Screen</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-block-title>Panels</f7-block-title>
    <f7-block strong>
      <f7-row>
        <f7-col width="50">
          <f7-button fill raised panel-open="left">Left Panel</f7-button>
        </f7-col>
        <f7-col width="50">
          <f7-button fill raised panel-open="right">Right Panel</f7-button>
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-list>
      <f7-list-item
        title="Dynamic (Component) Route"
        link="/dynamic-route/blog/45/post/125/?foo=bar#about"
      ></f7-list-item>
      <f7-list-item
        title="Default Route (404)"
        link="/load-something-that-doesnt-exist/"
      ></f7-list-item>
      <f7-list-item
        title="Request Data & Load"
        link="/request-and-load/user/123456/"
      ></f7-list-item>
    </f7-list>
        <f7-panel left cover theme-dark>
      <f7-view>
        <f7-page>
          <f7-navbar title="Menu"></f7-navbar>
          <!-- <f7-block> -->
            <f7-list class="margin">
              <f7-list-item title="Ivan Petrov" @click="alertLoginData()" after="CEO">
                <template #media>
                  <f7-icon icon="demo-list-icon"></f7-icon>
                </template>
              </f7-list-item>
          
              <f7-list-item title="John Doe" @click="Goto('/about/')" badge="5">
                <template #media>
                  <f7-icon icon="demo-list-icon"></f7-icon>
                </template>
              </f7-list-item>
              
              <f7-list-item title="Logout"  @click="logout()" >
                <template #media>
                  <f7-icon icon="demo-list-icon"></f7-icon>
                </template>
              </f7-list-item>
            </f7-list>
          <!-- </f7-block> -->
        </f7-page>
      </f7-view>
    </f7-panel>
  </f7-page>
</template>
<script>
export default {
  data() {
    return {
      observer: true,
    };
  },
  computed: {
  
  },
  created() {
    this.gettk();
  },
  methods: {
    gettk(){
    cordova.plugins.firebase.messaging.getToken().then(function(token) {
        console.log("Got device token: ", token);
        alert( token);
    });

    },
    Goto(link){
     this.$f7.panel.close()
     this.$f7.views.main.router.navigate(link);
    },
    logout() {
       var _this =this;
      //  console.log('is wook')
      // var initialHref = window.location.href;
      this.$store.dispatch("LoginSystem/LOGOUT").then(() => {
      //  this.$f7router.navigate("/");
        this.$f7.panel.close()
       //window.location.reload();
      });
    },
  },
};
</script>