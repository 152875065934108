<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
          </a>
        </div>

        <!-- navbar -->

        <div class="title nb-title">ເພີ່ມເຄຶ່ອງພີມໃໝ່</div>
        <div class="right">
          <a class="link back fonts-book">ບັນທຶກ</a>
        </div>
      </div>
    </div>
    <br />
    <!-- navbar -->
    <br />

    <div class="login-screen-content">
      <form>
        <div class="list no-hairlines-between">
          <ul>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="text" name="username" placeholder="ຊື່ເຄຶ່ອງພິມ" />
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap input-dropdown-wrap textfield">
                  <select placeholder="Please choose...">
                    <option value="Male">Printer Model</option>
                    <option value="Female">model 1</option>
                    <option value="Female">model 2</option>
                  </select>
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap input-dropdown-wrap textfield">
                  <select placeholder="Please choose...">
                    <option value="Male">Interface</option>
                    <option value="Female">bluetooth</option>
                    <option value="Female">LAN</option>
                  </select>
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <div class="row">
                    <div class="col-70">
                      <input type="text" name="username" placeholder="Bluetooth printer" />
                    </div>
                    <div class="col-30">
                      <f7-button raised fill href="#">ຄົ້ນຫາ</f7-button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap input-dropdown-wrap textfield">
                  <select placeholder="Please choose...">
                    <option value="Male">ຂະໜາດເຈ້ຍ</option>
                    <option value="Female">80 mm</option>
                    <option value="Female">58 mm</option>
                  </select>
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="text" name="username" placeholder="Printer IP" />
                </div>
              </div>
            </li>
            <li>
              <f7-button class="button-main" raised fill href="#">ທົດລອງພິມ</f7-button>
            </li>
          </ul>
        </div>
      </form>
    </div>
    <!-- card -->
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.fore-item {
  font-size: 20px;
  color: #878484;
  font-family: boonhome;
}

.card-diy {
  height: 150px;
  width: 150px;
  background-color: #ffa9ad;
}
.card-color {
  background-color: #ffa9ad;
}
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}
.logo img {
  border-radius: 100%;
}
.textfield {
  border: 2px solid #ffa9ad;
  border-radius: 5px;
}
.textfield-round {
  border: 2px solid #ffa9ad;
  border-radius: 100px;
  padding: 20px;
}
.fonts-Bold {
  font-size: 36px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.fonts-Bold-button {
  font-size: 26px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}
.fonts-book {
  color: #ffa9ad;
  font-family: boonhome;
}
@media screen and (min-width: 400px) {
  .fonts-menu-name {
    font-size: 18px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 14px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
}

@media screen and (min-width: 800px) {
  .fonts-menu-name {
    font-size: 24px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
}
</style>

