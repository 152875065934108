<template>
  <div class="page bg">
    <div class="navbar navbar-large navbar-transparent">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Back</span>
          </a>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="login-screen-content">
        <div class="login-screen-title fonts-Bold shadow">ເລືອກປະເພດຮ້ານຂອງເຈົ້າ</div>
        <div class="block">
          <div class="row">
            <div class="col" style="min-width: 20px"></div>
            <div class="col">
              <hr style="height:2px;border-width:0;color:gray;background-color:gray" />
            </div>
            <div class="col" style="min-width: 20px"></div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <f7-card class="card-diy card-color-Activate center">
          <f7-link href="/registerShop/">
            <f7-card-content>
              <f7-row>
                <span class="fore-color">SHOP</span>
              </f7-row>
            </f7-card-content>
          </f7-link>
        </f7-card>
        <f7-card class="card-diy card-color-Activate center">
          <f7-link href="/registerShop/">
            <f7-card-content>
              <f7-row>
                <span class="fore-color">Cafe</span>
              </f7-row>
            </f7-card-content>
          </f7-link>
        </f7-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      imgSrc: "/assets-src/apple-touch-icon.png",
      // src: "https://unpkg.com/vue-image-upload-resize",
    };
  },
};
</script>
<style scoped>
.container {
  display: flex; /* or inline-flex */
}
.fore-color {
  font-size: 20px;
  font-weight: bold;
  color: #ffff;
  font-family: boonhome;
  margin-top: 45px;
}

.card-diy {
  height: 150px;
  width: 150px;
  background-color: #ffa9ad;
}
.card-color-Activated {
  background-color: #ffa9ad;
}
.card-color-notActivate {
  background-color: #ffe0e8;
}
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}
.logo img {
  border-radius: 100%;
}
.textfield {
  border: 2px solid #ffa9ad;
  border-radius: 5px;
}
.fonts-Bold {
  font-size: 36px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.fonts-Bold-button {
  font-size: 26px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}
.fonts-book {
  color: #ffa9ad;
  font-family: boonhome;
}
</style>