<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a  class="link back icon-color">
            <i class="icon icon-back"></i>
          </a>
        </div>
        <!-- navbar -->
        <div class="title nb-title">ລາຍການອໍເດີ</div>
        <div class="right">
          <a class="link icon-color">
            <i class="icon f7-icons"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- navbar -->

    <!-- card -->

    <div class="page-content">
      <div class="block list-title">ທັງຫມົດ: {{ countmenu }}​ ເມນູ</div>
      <div class="list inset center">
        <ul>
          <div v-for="(d, k) in showorders" :key="k">
            <li class="swipeout">
              <a href="#" class="item-content swipeout-content item-content">
                <div class="item-media">
                  <i class="icon icon-f7"></i>
                </div>
                <div class="item-inner item-style item-cell">
                  <div class="item-row">
                    <div class="item-cell">{{ d.name }}</div>
                    <div class="item-cell" style="font-weight: bold">
                      {{ d.qty }}
                    </div>
                    <div class="item-cell">
                      <div class="row">
                        <div class="col">
                          <span>{{ d.price }} ₭</span>
                        </div>
                        <div class="col">
                          <a href="#" @click="confirm(d)">
                            <i class="f7-icons">xmark</i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </div>
        </ul>
        <div class="block-footer">
          <!-- <div>
            <f7-button raised fill @click="sadasdas">ສົ່ງຫ້ອງຄົວ</f7-button>
          </div>
          <br /> -->
          <f7-button class="button-main" raised fill @click="sendoders"
            >ບັນທຶກເຂົ້າລາຍການ</f7-button
          >
        </div>
      </div>
      <!-- <h1>{{ fix }}</h1> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      order: {},
      fix: {
        141: { name: "Esspresso", pid: 141, price: 123, qty: 1 },
        143: {
          name: "coco",
          pid: 143,
          price: 15000,
          qty: 1,
        },
        144: { name: "tae", pid: 144, price: 10000, qty: 1 },
      },
    };
  },
  computed: {
    ...mapGetters("shop", ["showorders", "getsendorder"]),
    countmenu: function () {
      // this.order = this.showorders;
      let a = this.showorders;
      return Object.keys(a).length;
    },
  },
  methods: {
    cal() {
      const j = this.fix.Map((item) => item.data);
      this.fix.splice(j, 1);
      console.log(fix);
    },

    del: function (value) {
      this.$store.dispatch("shop/A_DELETE_ORDER", value);
      delete this.showorders[value.pid];
    },
    sendoders: function (value) {
      var data = {
        tid: this.$f7route.params.tid,
        pros: Object.values(this.getsendorder),
      };
      this.$store.dispatch("shop/A_SEND_ORDER", data);
    },
    async confirm(value) {
      var _this = this;
      await this.$f7.dialog.confirm(
        "Are you sure to Delete this item ",
        function () {
          var item = {
            pid: value.pid,
            qty: value.qty,
            price: value.price,
            name: value.name,
          };

          console.log("test1" + item);
          _this.del(item);
        }
      );
    },
  },
};
</script>
<style scoped>
.icon-color {
  color: #ffa9ad;
}
.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}
.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}
.fonts-book {
  color: #ffa9ad;
  font-family: boonhome;
}
</style>

