<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Back</span>
          </a>
        </div>
        <div class="title nb-title">ຍອດຂາຍທັງຫມົດ</div>
        <div class="right">
          <a href="#" class="link"></a>
        </div>
       
      </div>
    </div>

    <!-- navbar -->

    <!-- navbar -->
    <!-- content -->
    <div class="page-content">
      <div class="block">
        <div class="row">
          <div class="col">
            <div class="list no-hairlines-between">
              <div class="fonts-menu-price">ເລືອກເພື່ອຄັດກອງໃບບິນ</div>
              <ul>
                <li class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-input-wrap textfield">
                      <input type="datetime-local" @change="showreport" v-model="start" placeholder="Please choose..." />
                    </div>
                  </div>
                  <div class="item-inner">
                    <div class="item-input-wrap textfield">
                      <input type="datetime-local" @change="showreport" v-model="end" placeholder="Please choose..." />
                    </div>
                  </div>

                  <!-- <div class="item-inner">
                    <div class="item-input-wrap input-dropdown-wrap textfield">
                      <select placeholder="Please choose...">
                        <option>ເລືອກພະນັກງານ</option>
                        <option>A1</option>
                        <option>A2</option>
                        <option>A3</option>
                      </select>
                    </div>
                  </div> -->
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="block list-title justify-content-space-between">
        <f7-row>
          <span class="fonts-menu-title-a">ລາຍການທັງຫມົດ:</span>
          <span class="fonts-menu-title-a">ລວມ: {{total | tls}} ₭</span>
        </f7-row>
      </div>
      <div class="list inset media-list">
        <ul>
          <div v-for="d in reports" :key="d.tsid">
            <li>
              <a :href="'/billdetail/'+d.tsid" class="item-link item-content">
                <div class="item-media">
                  <a class="link icon-color">
                    <i class="icon f7-icons ic">doc_plaintext</i>
                  </a>
                </div>
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title fonts-menu-name">Bill no:{{d.tsid}}</div>
                    <div class="item-after fonts-menu-name">{{d.total | tls}} ₭</div>
                  </div>
                  <div class="item-subtitle fonts-menu-price">ວັນທີ: {{format_date(d.tsdate)}}</div>
                </div>
              </a>
            </li>
          </div>
        </ul>
      </div>
    </div>
    <!-- content -->
  </div>
</template>
<script>
// import Menu from "../components/menu.vue";
import { axios_app } from "@/js/axios-app";
import moment from 'moment'

export default {
    data() {
    return {
      jobIsDone: false,
      start:"",
      end:"",
      total:0,
      billcount:0,
      reports:[]
    };
  },
  // components: { Menu },
  props: {
    f7router: Object,
  },
  methods: {
  showreport: function(){
        axios_app({
              method: "POST",
              url: "/v1/mumu/reports",
              data: {
                start:moment(this.start),
                end:moment(this.end)
              },
            })
              .then((resp) => {
                this.total = resp.data.s.total;
                this.billcount = resp.data.s.billcount;
                this.reports = resp.data.reportlist;
              })
              .catch((err) => {
                // reject(err);
              });
  },
  format_date(value){
         if (value) {
           return moment(String(value)).format('YYYY-MM-DD HH:mm')
          }
      },
  }
};
</script>
<style scoped>
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}

@media screen and (min-width: 300px) {
  .fonts-menu-title-a {
    font-size: 19px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-name {
    font-size: 15px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 13px;
    color: #3c3c3c;
    font-weight: normal;
    font-family: boonhome;
  }
  img {
    width: 70px;
  }
}

@media screen and (min-width: 800px) {
  .fonts-menu-title-a {
    font-size: 25px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-name {
    font-size: 24px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #3c3c3c;
    font-weight: normal;
    font-family: boonhome;
  }
  img {
    width: 100px;
  }
}
</style>

