<template>
  <div class="page bg">
    <div class="navbar navbar-large navbar-transparent">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Back</span>
          </a>
        </div>
      </div>
    </div>
    <div class="login-screen-content">
      <div class="login-screen-title fonts-Bold shadow">ຂໍ້ມູນຮ້ານ</div>
      <div class="block">
        <div class="row">
          <div class="col" style="min-width: 20px"></div>
          <div class="col">
            <hr style="height:2px;border-width:0;color:gray;background-color:gray" />
          </div>
          <div class="col" style="min-width: 20px"></div>
        </div>
      </div>

      <form>
        <div class="list no-hairlines-between">
          <ul>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="text" name="username" placeholder="ຊື່ຮ້ານ" />
                </div>
              </div>
            </li>

            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="text" name="username" placeholder="ເບີໂທຕິດຕໍ່" />
                </div>
              </div>
            </li>
            <li>
              <div class="row" style="padding-left: 23px;">
                <div class="col-33">
                  <div class="item-inner">
                    <div class="item-input-wrap input-dropdown-wrap textfield">
                      <select placeholder="Please choose...">
                        <option value hidden>ບ້ານ</option>
                        <option value="Male">ຈັນທະບູລີ</option>
                        <option value="Female">ໄຂທານີ</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-33">
                  <div class="item-inner">
                    <div class="item-input-wrap input-dropdown-wrap textfield">
                      <select placeholder="Please choose...">
                        <option value hidden>ເມືອງ</option>
                        <option value="Male">ຈັນທະບູລີ</option>
                        <option value="Female">ໄຂທານີ</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-33">
                  <div class="item-inner">
                    <div class="item-input-wrap input-dropdown-wrap textfield">
                      <select placeholder="Please choose...">
                        <option value hidden>ແຂວງ</option>
                        <option value="Male">ຈັນທະບູລີ</option>
                        <option value="Female">ໄຂທານີ</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap input-dropdown-wrap textfield">
                  <select placeholder="Please choose...">
                    <option value hidden>ສະກຸນເງິນ</option>
                    <option value="Male">ຈັນທະບູລີ</option>
                    <option value="Female">ໄຂທານີ</option>
                  </select>
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <input type="file" accept="image/jpeg" @change="uploadImage" />
            </li>
            <li>
              <div v-for="(image, key) in images" :key="key">
                <div>
                  <img class="preview" :ref="'image'" />
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="block">
          <div class="col button-main">
            <f7-button raised fill href="/table/">ຢືນຢັນ</f7-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  methods: {
    uploadImage(e) {
      let vm = this;
      var selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        console.log(selectedFiles[i]);
        this.images.push(selectedFiles[i]);
      }

      for (let i = 0; i < this.images.length; i++) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.$refs.image[i].src = reader.result;

          console.log(this.$refs.image[i].src);
        };

        reader.readAsDataURL(this.images[i]);
      }
    },
  },
  data() {
    return {
      images: [],
      imgSrc: "/assets-src/apple-touch-icon.png",
     
    };
  },
};
</script>
<style scoped>
.center {
  align-items: center;
  text-align: center;
}

.fonts-Bold {
  font-size: 36px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.preview {
  margin-left: 5%;
  width: 30%;
}
.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}
</style>