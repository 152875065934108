<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Back</span>
          </a>
        </div>
        <div class="title nb-title">ສ່ວນຫຼຸດ</div>
        <div class="right">
          <a href="newDiscount" class="link icon-color">
            <i class="icon f7-icons">plus_circle</i>
          </a>
        </div>
        <div class="subnavbar bg" style="padding-left: 25px; padding-right: 25px;">
          <form class="searchbar">
            <div class="searchbar-inner">
              <div class="searchbar-input-wrap">
                <input type="search" placeholder="Search" />
                <i class="searchbar-icon"></i>
                <span class="input-clear-button"></span>
              </div>
              <span class="searchbar-disable-button if-not-aurora">Cancel</span>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- navbar -->

    <!-- navbar -->
    <!-- content -->
    <div class="page-content">
      <div class="list inset media-list">
        <ul>
          <div v-for="n in 3" :key="n">
            <li>
              <a href="/billdetail/" class="item-link item-content">
                <div class="item-media">
                  <a class="link icon-color">
                    <i class="icon f7-icons ic">tag_fill</i>
                  </a>
                </div>
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title fonts-menu-name">pro1</div>
                    <div class="item-after fonts-menu-price">10.000 ₭</div>
                  </div>
                  <div class="item-subtitle fonts-menu-name">-</div>
                </div>
              </a>
            </li>
          </div>
          <div v-for="n in 3" :key="n">
            <li>
              <a href="/billdetail/" class="item-link item-content">
                <div class="item-media">
                  <a class="link icon-color">
                    <i class="icon f7-icons ic">tag_fill</i>
                  </a>
                </div>
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title fonts-menu-name">pro3</div>
                    <div class="item-after fonts-menu-name">10%</div>
                  </div>
                  <div class="item-subtitle fonts-menu-name">-</div>
                </div>
              </a>
            </li>
          </div>
        </ul>
      </div>
    </div>
    <!-- content -->
  </div>
</template>
<script>
import Menu from "../components/menu.vue";

export default {
  components: { Menu },
  props: {
    f7router: Object,
  },
};
</script>
<style scoped>
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}

@media screen and (min-width: 300px) {
  .fonts-menu-name {
    font-size: 15px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 13px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  img {
    width: 70px;
  }
  .ic {
    font-size: 35px;
  }
}

@media screen and (min-width: 800px) {
  .fonts-menu-name {
    font-size: 24px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  img {
    width: 100px;
  }
  .ic {
    font-size: 70px;
  }
}
</style>

