<template>
  <f7-page class="login-page" no-toolbar>
      <div class="counter">
          {{ $store.state.counter}}
      </div>
      <button @click="$store.dispatch('m')">-</button>
      <button @click="$store.dispatch('p')">+</button>
      {{$store.getters.counterSq}}
  </f7-page>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  // data() {
  //   return {
  //       counter:0
  //   };
  // },
  // methods: {
  //     p(){
  //         this.counter++
  //     },
  //     m(){
  //         this.counter--
  //     }
  // }
};
</script>

<style scoped>
.center
{
  align-items: center;
  text-align: center;
 
}
.logo
{
 border-radius: 10%;
}
</style>