<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
          </a>
        </div>

        <!-- navbar -->

        <div class="title nb-title">ແພັກເກດ</div>
        <div class="right">
          <a href="#" class="link icon-color">
            <i class="icon f7-icons"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- navbar -->

    <!-- card -->

    <div class="page-content">
      <div class="login-screen-content">
        <div class="list inset media-list">
          <ul>
            <div class="card demo-facebook-card center">
              <div class="card-header" style="background-color: #ffa9ad;">
                <div class="fonts-menu-a">25.000 ກີບ</div>
              </div>
              <div class="card-content card-diy">
                <img
                  class="center-a"
                  src="https://www.researchgate.net/profile/Hafiza-Abas/publication/288303807/figure/fig1/AS:311239419940864@1451216668048/An-example-of-QR-code.png"
                />
                <p class="fonts-menu-price">
                  ກະລຸນາສະແກນ QR code ນີ້ເພື່ອ
                  ຊຳລະເງິນ
                </p>
              </div>
            </div>
          </ul>
        </div>
        <!-- <f7-card class="card-diy">
          <div class="row ">
            <a href="# ">
              <div class="row">
                <span class="fonts-menu-name center">10.000 ກີບ/ເດືອນ</span>
              </div>
              <div class="row">
                <div class="col resizable">
                  <hr style="height:2px;border-width:0;color:white;background-color:white" />
                </div>
              </div>
              <div class="row justify-content-space-between">
                <h1>ນຳໃຊ້ໄດ້ 50 ບິນ/ມື້</h1>
                <h1>ນຳໃຊ້ໄດ້ 50 ບິນ/ມື້</h1>
              </div>
            </a>
          </div>
        </f7-card>-->
      </div>
    </div>
  </div>
</template>
<script>
import Menu from "../components/menu.vue";

export default {
  components: { Menu },
};
</script>
<style scoped>
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}
.center-a {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.card-diy {
  border-left: 3px solid #ffa9ad;
  border-right: 3px solid #ffa9ad;
  border-bottom: 3px solid #ffa9ad;

  background-color: #ffff;
}

.shadow {
  text-shadow: 2px 4px 4px #0000004d;
}

@media screen and (min-width: 300px) {
  .fonts-menu-name {
    font-size: 29px;
    color: #232b33;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-a {
    font-size: 20px;
    color: #ffff;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 15px;
    color: #232b33;
    font-weight: normal;
    font-family: boonhome;
  }
  .ic-a {
    font-size: 15px;
    padding-top: 4px;
    padding-left: 5px;
  }
  img {
    font-size: 100px;
    width: 100%;
    height: auto;
    color: #ffff;
  }
}

@media screen and (min-width: 700px) {
  .fonts-menu-name {
    font-size: 35px;
    color: #232b33;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-a {
    font-size: 30px;
    color: #ffff;
    font-weight: bold;
    font-family: boonhome;
  }

  .fonts-menu-price {
    font-size: 20px;
    color: #232b33;
    font-weight: normal;
    font-family: boonhome;
  }
  .fonts-menu-price-b {
    font-size: 20px;
    color: #ffa9ad;
    font-weight: normal;
    font-family: boonhome;
  }
  .ic-a {
    font-size: 20px;
    padding-top: 5px;
    padding-left: 10px;
  }
  img {
    font-size: 100px;
  }
  .mg-car {
    margin: 20px;
  }
}
</style>

<style>
.demo-card-header-pic .card-header {
  height: 40vw;
  background-size: cover;
  background-position: center;
  color: #fff;
}

.demo-card-header-pic .card-content-padding .date {
  color: #8e8e93;
}

.demo-facebook-card .card-header {
  display: block;
  padding: 10px;
}

.demo-facebook-card .demo-facebook-avatar {
  float: left;
}

.demo-facebook-card .demo-facebook-name {
  margin-left: 44px;
  font-size: 14px;
  font-weight: 500;
}

.demo-facebook-card .demo-facebook-date {
  margin-left: 44px;
  font-size: 13px;
  color: #8e8e93;
}

.demo-facebook-card .card-footer {
  background: #fafafa;
}

.demo-facebook-card .card-footer a {
  color: #81848b;
  font-weight: 500;
}

.demo-facebook-card .card-content img {
  display: block;
}

.demo-facebook-card .card-content-padding {
  padding: 15px 10px;
}

.demo-facebook-card .card-content-padding .likes {
  color: #8e8e93;
}
</style>