import { axios_app } from "@/js/axios-app";
export default {
  namespaced: true,
  state: {
    u_location: {},
  },
  mutations: {
    request(state) {
      state.status = "loading";
      state.error = "";
    },
    success(state, data) {
      state.status = "success";
      state.u_location = data;
    },
    error(state, error) {
      state.status = "error";
      state.error = error;
    },
    clear(state) {
      state.u_location = {};
    },
  },
  actions: {
    ADD_LOCATION({ commit }) {
      return new Promise(async (resolve, reject) => {
        var tmp_position ;
        await navigator.geolocation.getCurrentPosition(async function(position) {
            await commit("request");
            await axios_app({
              method: "POST",
              url: "/v1/location/add-user_location",
              data: {longitude:position.coords.longitude ,latitude:position.coords.latitude },
            })
              .then((resp) => {
                commit("success", {longitude:position.coords.longitude ,latitude:position.coords.latitude });
                resolve(resp);
              })
              .catch((err) => {
                commit("error", err);
                reject(err);
              });
        }, function onError(err) {
          console.log();
          commit("error",err);
          reject(err);
         });
      });
    },
  },
  getters: {
    u_location: (state) => state.u_location,
  },
};
