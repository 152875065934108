<template>
  <div class="page bg">
    <div class="navbar navbar-large navbar-transparent">
      <div class="navbar-bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Back</span>
          </a>
        </div>
      </div>
    </div>
    <div class="login-screen-content">
      <div class="login-screen-title fonts-Bold shadow">ຂໍ້ມູນຜູ້ໃຊ້</div>
      <div class="block">
        <div class="row">
          <div class="col resizable" style="min-width: 20px"></div>
          <div class="col resizable">
            <hr style="height:2px;border-width:0;color:gray;background-color:gray" />
          </div>
          <div class="col resizable" style="min-width: 20px"></div>
        </div>
      </div>

      <form>
        <div class="list no-hairlines-between">
          <ul>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="text" placeholder="ຊື່ຜູ້ໃຊ້" v-model="username" />
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="col">
                <div class="item-inner">
                  <div class="item-input-wrap textfield">
                    <input type="text" placeholder="ຊື່" v-model="name" />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="item-inner">
                  <div class="item-input-wrap textfield">
                    <input type="text" placeholder="ນາມສະກຸນ" v-model="surname" />
                  </div>
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="text" placeholder="ອີເມວ" v-model="email" />
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="password" placeholder="ລະຫັດຜ່ານ" v-model="password" />
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="password" placeholder="ຢືນຢັນລະຫັດຜ່ານ" v-model="confirmpassword" />
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="block">
          <div class="col button-main">
            <f7-button raised fill href="/registerchoose/">ຖັດໄປ</f7-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    register: function () {
      this.$f7.dialog.alert(
        "username: " + this.username + ", name: " + this.name
      );
    },
  },

  data() {
    return {
      username: "",
      name: "",
      surname: "",
      email: "",
      password: "",
      confirmpassword: "",
    };
  },
};
</script>
<style scoped>
.center {
  align-items: center;
  text-align: center;
}

.fonts-Bold {
  font-size: 36px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
/* .fonts-Bold-button {
  font-size: 26px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
} */
.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}
</style>