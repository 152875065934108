<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
          </a>
        </div>

        <!-- navbar -->

        <div class="title nb-title">ແພັກເກດ</div>
        <div class="right">
          <a href="#" class="link icon-color">
            <i class="icon f7-icons"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- navbar -->

    <!-- card -->

    <div class="page-content">
      <div class="block">
        <div class="list inset media-list">
          <ul>
            <f7-card class="card-diy">
              <li>
                <a href="/qrcode/" class="item-content mg-car">
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title fonts-menu-name">10.000</div>
                      <div class="item-title fonts-menu-a">ກີບ/ເດືອນ</div>
                      <div class="item-after fonts-menu-price-b">
                        ສະໝັກເລີຍ
                        <i class="icon f7-icons ic-a">arrow_right</i>
                      </div>
                    </div>
                    <div class="item-subtitle fonts-menu-price">ນຳໃຊ້ໄດ້ 50 ບິນ/ມື້</div>
                  </div>
                </a>
              </li>
            </f7-card>
            <f7-card class="card-diy">
              <li>
                <a href="#" class="item-content mg-car">
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title fonts-menu-name">25.000</div>
                      <div class="item-title fonts-menu-a">ກີບ/ເດືອນ</div>
                      <div class="item-after fonts-menu-price-b">
                        ສະໝັກເລີຍ
                        <i class="icon f7-icons ic-a">arrow_right</i>
                      </div>
                    </div>
                    <div class="item-subtitle fonts-menu-price">ນຳໃຊ້ໄດ້ 150 ບິນ/ມື້</div>
                  </div>
                </a>
              </li>
            </f7-card>
            <f7-card class="card-diy">
              <li>
                <a href="#" class="item-content mg-car">
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title fonts-menu-name">50.000</div>
                      <div class="item-title fonts-menu-a">ກີບ/ເດືອນ</div>
                      <div class="item-after fonts-menu-price-b">
                        ສະໝັກເລີຍ
                        <i class="icon f7-icons ic-a">arrow_right</i>
                      </div>
                    </div>
                    <div class="item-subtitle fonts-menu-price">ນຳໃຊ້ໄດ້ Unlimited</div>
                  </div>
                </a>
              </li>
            </f7-card>
          </ul>
        </div>
        <!-- <f7-card class="card-diy">
          <div class="row ">
            <a href="# ">
              <div class="row">
                <span class="fonts-menu-name center">10.000 ກີບ/ເດືອນ</span>
              </div>
              <div class="row">
                <div class="col resizable">
                  <hr style="height:2px;border-width:0;color:white;background-color:white" />
                </div>
              </div>
              <div class="row justify-content-space-between">
                <h1>ນຳໃຊ້ໄດ້ 50 ບິນ/ມື້</h1>
                <h1>ນຳໃຊ້ໄດ້ 50 ບິນ/ມື້</h1>
              </div>
            </a>
          </div>
        </f7-card>-->
      </div>
    </div>
  </div>
</template>
<script>
import Menu from "../components/menu.vue";

export default {
  components: { Menu },
};
</script>
<style scoped>
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}

.card-diy {
  border: 2px solid #ffa9ad;
  border-radius: 100px;
  background-color: #ffff;
}

.shadow {
  text-shadow: 2px 4px 4px #0000004d;
}

@media screen and (min-width: 300px) {
  .fonts-menu-name {
    font-size: 29px;
    color: #232b33;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-a {
    font-size: 14px;
    color: #232b33;
    font-weight: bold;
    font-family: boonhome;
    padding-top: 13px;
    padding-left: 5px;
  }
  .fonts-menu-price {
    font-size: 15px;
    color: #232b33;
    font-weight: normal;
    font-family: boonhome;
  }
  .ic-a {
    font-size: 15px;
    padding-top: 4px;
    padding-left: 5px;
  }
  .img {
    font-size: 60px;
    color: #ffff;
  }
}

@media screen and (min-width: 700px) {
  .fonts-menu-name {
    font-size: 35px;
    color: #232b33;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-a {
    font-size: 25px;
    color: #232b33;
    font-weight: bold;
    font-family: boonhome;
    padding-top: 10px;
    padding-left: 5px;
  }

  .fonts-menu-price {
    font-size: 20px;
    color: #232b33;
    font-weight: normal;
    font-family: boonhome;
  }
  .fonts-menu-price-b {
    font-size: 20px;
    color: #ffa9ad;
    font-weight: normal;
    font-family: boonhome;
  }
  .ic-a {
    font-size: 20px;
    padding-top: 5px;
    padding-left: 10px;
  }
  .img {
    font-size: 100px;
  }
  .mg-car {
    margin: 20px;
  }
}
</style>

