import { axios_authen } from "@/js/axios-authen";
export default {
  namespaced: true,
  state: {
    status: "",
    error: "",
    user_info: {},
  },
  mutations: {
    request(state) {
      state.status = "loading";
      state.error = "";
    },
    success(state, data) {
      state.status = "success";
      state.user_info = data;
    },
    error(state, error) {
      state.status = "error";
      state.error = error;
    },
    clear(state) {
      state.user_info = [];
    },
  },
  actions: {
    GET_INFO({ commit }) {
      return new Promise(async (resolve, reject) => {
        await commit("request");
        await commit("clear");
        await axios_authen({
          method: "GET",
          url: "/v1/user/user-infomation",
        })
          .then((resp) => {
            commit("success", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit("error", err);
            reject(err);
          });
      });
    },
  },
  getters: {
    user_info: (state) => state.user_info,
  },
};
