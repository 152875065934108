<template>
  <div
    product
    class="my-card elevation-10"
    :style="{ backgroundImage: 'url(' + picurl(product.pic)  + ')' }"
  >
    <div class="absolute align-items-flex-end" style="color: white">
      <div class="row">
        <span
          class="fonts-menu-a"
          style="padding-left: 5px; color: #fff; width: 100%"
          >{{ product.name }}</span
        >

        <div class="row">
          <div class="col-50">
            <span
              class="fonts-menu-b"
              style="padding-left: 5px; color: #ffa9ad"
              >{{ (product.price || 0).toLocaleString() }}</span
            >
          </div>
          <div class="col-50" style="padding-left: 15px">
            <div class="stepper" style="background-color: white">
              <div class="stepper-button-minus" @click="misus"></div>
              <div class="stepper-value">{{ showcounter }}</div>
              <div class="stepper-button-plus" @click="plus"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import eventHub from "../js/eventHub";
export default {
  props: {
    product: {
      type: Object,
    },
    tid: {
      type: String,
    },
  },

  data: () => ({
    counter: 0,
    src: "https://ms.mounoydev.com/mumu/e00620fc-a8a2-4cb0-ae88-a88d9bcbde74.webp",
  }),
  computed: {
    // a computed getter
    showcounter: function () {
      // `this` points to the vm instance
      return this.counter;
    },
  },
  mounted() {
    // adding eventHub listener
    eventHub.$on("resetcard", () => {
      this.counter = 0;
    });
  },
  beforeDestroy() {
    // removing eventHub listener
    eventHub.$off("resetcard");
  },
  methods: {
    picurl: function (file) {
      var pic = null;
      if (file == null) {
        pic = this.src;
      } else {
        var url = "https://ms.mounoydev.com/mumu/" + file;
        pic = url;
      }
      return pic;
    },
    misus: function (value) {
      // console.log("misus"+this.product.proid)
      if (this.counter > 0) {
        this.counter--;
        var item = {
          pid: this.product.proid,
          qty: this.counter,
          price: this.product.price,
          name: this.product.name,
        };
        this.$store.dispatch("shop/A_ADD_ORDER", item);
      }
    },
    plus: function (value) {
      if (this.counter >= 0) {
        this.counter++;
        var item = {
          pid: this.product.proid,
          qty: this.counter,
          price: this.product.price,
          name: this.product.name,
        };
        this.$store.dispatch("shop/A_ADD_ORDER", item);
      }
    },
    format: function (value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
<style scoped>
@media screen and (min-width: 300px) {
  .fonts-menu-name {
    font-size: 17px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 14px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-a {
    font-size: 18px;

    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-b {
    font-size: 20px;

    font-weight: bold;
    font-family: boonhome;
  }
  img {
    width: 100px;
  }
}

@media screen and (min-width: 800px) {
  .fonts-menu-name {
    font-size: 24px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-a {
    font-size: 20px;

    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-b {
    font-size: 23px;

    font-weight: bold;
    font-family: boonhome;
  }
  img {
    width: 150px;
  }
}

.my-card {
  width: 280px;
  height: 220px;
  margin: 10px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}
div.absolute {
  position: absolute;
  width: 100%;
  bottom: 0px;
  background-color: rgba(51, 49, 49, 0.582) !important;
}
.icon-color {
  color: #ffa9ad;
}
.center {
  align-items: center;
  text-align: center;
}
</style>