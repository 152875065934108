<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
          </a>
        </div>
        <!-- navbar -->
        <div class="title nb-title">ກວດກາລາຍການ</div>
        <div class="right">
          <a class="link icon-color">
            <i @click="pcprintbill" class="icon f7-icons">printer</i>
          </a>
        </div>
        <div class="subnavbar">
          <div class="list inline-labels no-hairlines-md" style="width: 100%">
            <ul>
              <li class="item-content item-input">
                <div class="item-media">
                  <i class="icon f7-icons" style="color: grey">search</i>
                </div>
                <div class="item-inner">
                  <div class="item-input-wrap">
                    <input
                      v-on:keyup.enter="searchBarcode"
                      v-model="search"
                      type="search"
                      @keydown="keysevent"
                      placeholder="ສະແກນ Barcode"
                    />
                    <span class="input-clear-button"></span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- navbar -->

    <!-- card -->

    <div class="page-content">
      <!-- {{shopinfo}} -->
      <!-- <button @click="showprinter"> showprinter </button>
      <button @click="g"> GGGG </button>
      <button @click="blist"> blist </button>
      <button @click="bcon"> connect </button>
      <button @click="bprint"> print </button>
      <button @click="bpos"> bpos </button>-->
      <!-- <button @click="buildbill">buildbill</button> -->
      <!-- <div id="pcmybillls"> -->
      <!-- <canvas id="mybillls"></canvas> -->
      <!-- </div> -->

      <div class="block list-title justify-content-space-between">
        <f7-row>
          <span class="fonts-menu-title-a">ທັງຫມົດ:{{ bill.c }} ເມນູ</span>
          <span class="fonts-menu-title-a"
            >ລວມ: {{ Number(bill.totol).toLocaleString() }}</span
          >
        </f7-row>
      </div>

      <div class="list inset center">
        <ul>
          <div v-for="(d, k) in bill.list" :key="k">
            <a href="#" class="item-content swipeout-content item-content">
              <div class="item-media">
                <i class="icon icon-f7"></i>
              </div>
              <div class="item-inner item-style item-cell">
                <div class="item-row">
                  <!-- <div class="item-cell">{{k+1}}</div> -->
                  <div class="item-cell">
                    <div style="font-weight: bold">{{ d.name }}</div>
                    <div>{{ d.barcode }}</div>
                  </div>
                  <!-- <div class="item-cell" style=" font-weight: bold;"></div> -->
                  <div class="item-cell">
                    {{ Number(d.price || 0).toLocaleString() }} x {{ d.qty }}
                  </div>
                  <div class="item-cell" style="font-weight: bold">
                    <div class="row">
                      <div class="col">
                        <span>
                          {{ Number(d.amount || 0).toLocaleString() }} ₭</span
                        >
                      </div>
                      <div class="col">
                        <a href="#" @click="confirm(d)">
                          <i class="f7-icons">xmark</i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </ul>
        <div id="btest" style="width: 300px">
          <!-- <H1>Welcome</H1>
          <H1>ຍິນດີຕອນຮັບ</H1>-->
        </div>

        <div class="block-footer">
          <div with="100%" class="col">
            <br />
            <f7-button
              style="
                background-color: green;
                font-weight: bold;
                font-family: boonhome;
              "
              raised
              fill
              :href="'/checkbill/' + this.$f7route.params.tid"
              >ຈ່າຍ</f7-button
            >
          </div>
        </div>
        <div style="display: none">
          <div id="bill">
            <div class="printing">
              <div class="invoice-title">
                <img
                  v-if="shopinfo.logo != '' || shopinfo.logo != null"
                  :src="shopinfo.logo"
                  style="width: 3cm"
                />
                <div id="main-title">
                  <h4 class="mmm" style="font-size: 20px">ໃບບິນ</h4>
                  <span class="fl12">#{{ bill.tsid }}</span>
                </div>
                <span class="fl12">ວັນທີ: {{ bill.date }}</span>
                <br />
                <span class="fl12">ເບີໂທ:{{ shopinfo.tel }}</span>
                <!-- <br /> -->
                <!-- <span class="fontlao fl12">ສາຂາ:{{brname}}</span> -->
              </div>
              <span>---------------------</span>
              <div>
                <table>
                  <thead>
                    <tr>
                      <td class="fl12 priceqt">ລາຍການ</td>
                      <td class="fl12 amount">ລາຄາ</td>
                    </tr>
                  </thead>
                  <thead class="fl12">
                    <template v-for="(d, k) in bill.list">
                      <tr>
                        <td class="fl12" colspan="2" style="font-weight: bold">
                          {{ d.name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="fl12" colspan="2">{{ d.barcode }}</td>
                      </tr>
                      <tr>
                        <td class="fl12 priceqt">
                          {{ d.price }} ກີບ x {{ d.qty }}
                        </td>
                        <td class="fl12 amount" style="font-weight: bold">
                          {{ (d.amount || 0).toLocaleString() }} ກີບ
                        </td>
                      </tr>
                    </template>
                  </thead>
                </table>
                <span>---------------------</span>
              </div>
            </div>
            <div class="center">
              <span style="font-size: 15pt"
                >ລວມ: {{ Number(bill.totol || 0).toLocaleString() }} ກີບ</span
              >
              <br />
              <span class="fl12"> ຂອບໃຈທີ່ໃຊ້ບໍລິການ</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Menu from "../components/menu.vue";
import { mapGetters } from "vuex";
import eventHub from "../js/eventHub";
import Printd from "printd";
// import * as htmlToImage from "html-to-image";
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

export default {
  computed: {
    ...mapGetters("shop", ["getsendorder", "bill", "shopinfo"]),
    countmenu: function () {
      let a = this.$store.state.bill.list;
      return Object.keys(a).length;
    },
    // countmenu:function(){
    //   let a =this.showorders;
    //  return Object.keys(a).length;
    // }
  },
  // components: { Menu },
  data() {
    return {
      email: null,
      password: null,
      search: null,
    };
  },
  created() {
    this.sendodersbill();
  },
  methods: {
    sendodersbill: function (value) {
      var data = {
        tid: this.$f7route.params.tid,
        pros: Object.values(this.getsendorder),
      };
      this.$store.dispatch("shop/A_SEND_ORDER_BILL", data);
      eventHub.$emit("resetcard");
    },
    async confirm(value) {
      var _this = this;
      await this.$f7.dialog.confirm(
        "Are you sure to Delete this item ",
        function () {
          var item = {
            proid: value.proid,
            tsid: _this.bill.tsid,
            tid: _this.$f7route.params.tid,
          };
          console.log("test1" + JSON.stringify(item));
          console.log("bill" + _this.bill.tsid);
          _this.$store.dispatch("shop/A_Delete_ORDER_BILL", item);
          eventHub.$emit("resetcard");
        }
      );
    },
    keysevent(e) {
      var value = e.target.value;
      //only allow a-z, A-Z, digits 0-9 and comma, with only 1 consecutive comma ...
      if (!e.key.match(/[a-zA-Z0-9]/) || e.key == ",") {
        e.preventDefault();
      }
    },
    searchBarcode() {
      if (this.search == null || this.search == "") {
        return this.$f7.dialog.alert(
          "ກະລຸນາໃສ່ຂໍ້ມູນໃຫ້ຄົບ ຫຼື ກວດເບິ່ງພາສາໃຫ້ເປັນພາສາອັງກິດ",
          "ລອງໃໝ່ອີກຄັ້ງ"
        );
      } else {
        var data = {
          tid: this.$f7route.params.tid,
          barcode: this.search,
        };
        this.$store
          .dispatch("shop/A_SEND_ORDER_byBarcode_BILL", data)
          .then((res) => {
            if (res.status == 209) {
              return this.$f7.dialog.alert(
                "ເລກ Barcode ນີ້ບໍ່ມີໃນລະບົບ",
                "ລອງໃໝ່ອີກຄັ້ງ"
              );
            }
          });
        eventHub.$emit("resetcard");
        this.search = "";
      }
    },
    // getmybill() {
    //   this.$store.dispatch("shop/A_GET_BILL", {
    //     tid: this.$f7route.params.tid,
    //   });
    // },
    pcprintbill() {
      const cssText = `
      @font-face {
  font-family: boonhome;
  src: url(../fonts/BoonHome-400.ttf);
  font-weight: normal;
  font-style: normal;

}
      *{ 
        font-family: boonhome !important; 
      }
      .fl12{
        font-size:12px;
      }
      .priceqt{
        width:20mm;
      }
      .amount{
        width:18mm;
        text-align: right  !important;
      }
      `;
      var d = new Printd();
      d.print(document.getElementById("bill"), [cssText]);
    },
    g() {
      var _this = this;
      var node = document.getElementById("btest");
      // console.log(document.getElementById("btest"))

      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          // var img = new Image();
          // img.src = dataUrl;
          // console.log(dataUrl);

          _this.print(dataUrl);
          // document.body.appendChild(img);
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },
    bdis() {
      BTPrinter.disconnect(
        function (data) {
          console.log("Success");
          console.log(data);
        },
        function (err) {
          console.log("Error");
          console.log(err);
        },
        "MHT-L5801"
      );
    },
    bpos() {
      BTPrinter.printPOSCommand(
        function (data) {
          console.log("Success");
          console.log(data);
        },
        function (err) {
          console.log("Error");
          console.log(err);
        },
        "0C"
      ); //OC is a POS command for page feed
    },
    blist() {
      BTPrinter.list(
        function (data) {
          console.log("Success");
          console.log(data); // paired bluetooth devices array
        },
        function (err) {
          console.log("Error");
          console.log(err);
        }
      );
    },
    bcon() {
      BTPrinter.connect(
        function (data) {
          console.log("Success Connect");
          console.log(data);
        },
        function (err) {
          console.log("Error");
          console.log(err);
        },
        "MHT-L5801"
      );
    },
    bprint() {
      var strText = "asdfasdf234h12o;34j12porjeko21ne3n2p " + "\n";
      BTPrinter.printText(
        function (data) {
          console.log("printText: " + data);
          //	window.plugins.toast.showLongBottom('printText: ' + data);
        },
        function (err) {
          console.error("printText: " + err);
          //		window.plugins.toast.showLongBottom('printText: ' + err);
        },
        strText
      );
      lo;
    },

    genprint(list) {
      var _this = this;
      console.log(list);
      var img = new Image();
      img.src = "static/icons/512x512.png"; //'img/logo.png';
      // img.src = list.logo;
      img.onload = function () {
        var c = document.getElementById("mybillls");
        var ctx = c.getContext("2d");
        //  c.width=900;
        c.width = 900;
        c.height = 1070 + list.lord.length * 40;
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, c.width, c.height);

        // ctx.fillStyle = "#FF0000";
        var h = 520;
        var w = (h * img.width) / img.height;
        var start = h + 30;
        ctx.drawImage(img, c.width / 2 - w / 2, 0, w, h);
        ctx.font = "bold 15px LAO";
        ctx.font = "bold 15px LAO";
        //  ctx.fillText(list.detail, 10, start);
        ctx.font = "bold 20px LAO";
        start = start + 20;
        ctx.fillStyle = "Black";
        ctx.beginPath();
        ctx.moveTo(0, start);
        ctx.lineTo(900, start);
        ctx.stroke();

        // start = start + 80;
        // ctx.font = "bold 50px LAO";
        // ctx.textAlign = "start";
        // ctx.fillText("ຜູ້ສົ່ງ ແມ່ຄ້ານ້ອຍ", 20, start);
        // ctx.textAlign = "right";
        // ctx.fillText("ໂທ 58689898", 890, start);
        // start = start + 80;
        // ctx.textAlign = "start";
        // ctx.fillText("ຜູ້ຮັບ ຫຼ້າ", 20, start);
        // ctx.textAlign = "right";
        // ctx.fillText("ໂທ 54492323", 890, start);
        // start = start + 80;
        // ctx.textAlign = "start";
        // ctx.fillText("ທີ່ຢູ່ລຸກຄ້າ: ວຽງຈະເລີນ", 20, start);
        // start = start + 80;
        // ctx.fillText("ຄ່າສິນຄ້າ", 20, start);
        // ctx.textAlign = "right";
        // ctx.fillText("139,000", 890, start);
        // start = start + 80;
        // ctx.textAlign = "start";
        // ctx.fillText("ຄ່າຂົນສົ່ງ (ຈ່າຍປາຍທາງ)", 20, start);
        // ctx.textAlign = "right";
        // ctx.fillText("10,000", 890, start);
        // start = start + 80;
        // ctx.textAlign = "start";
        // ctx.fillText("ລວມ", 20, start);
        // ctx.textAlign = "right";
        // ctx.fillText("149,000", 890, start);
        // start = start + 80;

        start = start + 15;
        ctx.beginPath();
        ctx.moveTo(0, start);
        ctx.lineTo(400, start);
        ctx.stroke();
        start = start + 30;
        var total = 0;
        // console.log(list.order.length);
        for (var i = 0; i < list.lord.length; i++) {
          var d = list.lord[i];
          console.log(d);
          // ctx.font = "70px";

          ctx.textAlign = "center";
          ctx.fillText(i + 1, 20, start);
          ctx.textAlign = "center";
          ctx.font = "bold 20px LAO";
          ctx.fillText(d.name, 100, start);
          ctx.textAlign = "right";
          ctx.font = "bold 20px LAO";
          ctx.fillText(d.price.toLocaleString(), 250, start);
          ctx.textAlign = "center";
          ctx.fillText(d.qty, 280, start);
          ctx.textAlign = "right";
          ctx.fillText(d.amount.toLocaleString(), 380, start);
          ctx.textAlign = "start";
          total = total + Number(d.amount);
          start = start + 40;
        }

        console.log(c.toDataURL());
        _this.print(c.toDataURL());

        // var bill = list.bill[0];
        // start = start + 20;
        // // ctx.font = "75px";
        // ctx.font = "bold 30px LAO";
        // ctx.textAlign = "right";
        // if (bill.total != bill.subtotal) {

        // ctx.fillText("ລວມ:   " + bill.subtotal.toLocaleString() + " ກິບ", 380, start, 1000);
        // start = start + 40;
        // }
        // ctx.textAlign="right";
        // if(bill.dis!="0") {
        //     ctx.fillText("ສ່ວນຫລຸດ (" + bill.dis + " %) :" + bill.disval.toLocaleString() + " ກິບ", 380, start, 1000);
        //     start = start + 40;
        // }
        // if(bill.atm!="0"){
        // ctx.fillText("ຈາກຜ່ານບັດ  ("+bill.atm+" %) :"+bill.atmval.toLocaleString()+" ກິບ",380,start,1000);
        // start = start+40;
        // }
        // ctx.fillText("ລວມທັງຫມົດ:   "+bill.total.toLocaleString()+" ກິບ",380,start,1000);
        // start = start+40;
        // ctx.fillText("ລວມ USD:   " + bill.usd.toLocaleString() + " USD",380,start,1000);
        // start = start+40;
        // ctx.fillText("ລວມ THB:   " + bill.thb.toLocaleString() + " THB",380,start,1000);

        // if(bill.getthb!="0"){
        //     start = start + 20;
        //     ctx.beginPath();
        //     ctx.moveTo(0, start);
        //     ctx.lineTo(400, start);
        //     ctx.stroke();
        // start = start+40;
        // ctx.fillText("ຮັບເງິນ :   " + bill.getthb.toLocaleString() + " THB",380,start,1000);
        // start = start+40;
        // ctx.fillText("ເງິນທອນ :   " + bill.changethb.toLocaleString() + " THB",380,start,1000);
        //     start = start+40;
        // }

        // if(bill.getlak!="0"){
        //     start = start + 20;
        //     ctx.beginPath();
        //     ctx.moveTo(0, start);
        //     ctx.lineTo(400, start);
        //     ctx.stroke();
        //     start = start+40;
        //     ctx.fillText("ຮັບເງິນ :   " + bill.getlak.toLocaleString() + " LAK",380,start,1000);
        //     start = start+40;
        //     ctx.fillText("ເງິນທອນ :   " + bill.changelak.toLocaleString() + " LAK",380,start,1000);
        //     start = start+40;
        // }
        // if(bill.getusd!="0"){
        //     start = start + 20;
        //     ctx.beginPath();
        //     ctx.moveTo(0, start);
        //     ctx.lineTo(400, start);
        //     ctx.stroke();
        //     start = start+40;
        //     ctx.fillText("ຮັບເງິນ :   " + bill.getusd.toLocaleString() + " USD",380,start,1000);
        //     start = start+40;
        //     ctx.fillText("ເງິນທອນ :   " + bill.changeusd.toLocaleString() + " USD",380,start,1000);
        //     start = start+40;
        // }

        // start = start+100;
      };
    },
    buildbill() {
      // var data = {
      //   lord: [
      //     {
      //       name: "ຈື່ນມັນ",
      //       price: 1000,
      //       qty: 1,
      //       amount: 1000,
      //     },
      //   ],
      // };
      var data = {
        lord: this.bill.list,
      };
      this.genprint(data);

      //       //  console.log("")
      //        html2canvas(document.getElementById('mybillls'),{
      //     windowWidth: "300px",
      //     windowHeight: "300px"
      // }).then(function(canvas) {
      //          console.log(canvas.toDataURL('image/png'))
      //          // document.body.appendChild(canvas);
      //       });
    },
    showprinter() {
      var logQuote = function (quote) {
        console.log(quote);
        // alert(quote)
      };

      //   this.ThermalPrinter.
      //  cordova.plugins.
      ThermalPrinter.listPrinters(
        {
          type: "bluetooth",
        },
        logQuote,
        logQuote
      );
      //0F:02:17:21:44:C0
    },
    print: function (pic) {
      console.log(window);

      var logQuote = function (quote) {
        console.log(quote);
        alert(quote);
      };

      var pimg = function (ppdata) {
        ThermalPrinter.printFormattedText(
          {
            type: "bluetooth",
            // id:"DC:0D:30:3E:4C:84",// label printer
            // id: "0F:02:17:21:44:C0",  // black printer
            id: "DC:0D:30:22:53:50", // electic printer
            // text:"<img>"+ppdata+"</img>\n"+ "[L]<font size='tall'>Customer ຫກດ:</font>\n"
            text: ppdata,
            // text:"[C]<qrcode size='40'>00020101021133380004BCEL0106ONEPAY0216mch5a45e6c37f2b6520457325303418540410005802LA6003VTE622101000505xxxxx070008006304F69E</qrcode>"
          },
          logQuote,
          logQuote
        );
      };
      // this.ThermalPrinter.
      //cordova.plugins.
      //  ThermalPrinter.listPrinters({
      //     type:"bluetooth"
      //   },logQuote,logQuote);
      //0F:02:17:21:44:C0

      ThermalPrinter.bitmapToHexadecimalString(
        {
          type: "bluetooth",
          id: "DC:0D:30:3E:4C:84",
          base64: pic,
        },
        pimg,
        logQuote
      );
    },
    login: function () {
      if (localStorage.getItem("uuid") === null)
        localStorage.setItem("uuid", uuidv4());
      if (!this.email || !this.password)
        return this.$f7.dialog.alert("ກະລຸນາໃສ່ຂໍ້ມູນ", "ລອງໃໝ່ອີກຄັ້ງ");
      if (this.password.length < 6)
        return this.$f7.dialog.alert(
          "Email or password not match",
          "ລອງໃໝ່ອີກຄັ້ງ"
        );

      this.$store
        .dispatch("LoginSystem/LOGIN", {
          app: "mumu",
          email: this.email,
          password: this.password,
          uuid: localStorage.getItem("uuid"),
        })
        .then((res) => {
          if (res.status === 201) {
            setTimeout(() => {
              this.$f7router.navigate("/home/");
            }, 500);
          }
          if (res.status === 200) {
            this.$f7.dialog.alert(
              res.data.stt || res.data.STT,
              "ລອງໃໝ່ອີກຄັ້ງ"
            );
          }
        })
        .catch((err) => console.log(err));
    },
    loginfb: function () {
      if (localStorage.getItem("uuid") === null)
        localStorage.setItem("uuid", uuidv4());
      var _this = this;
      console.log(this);
      var fbLoginSuccess = function (userData) {
        if (userData.status == "connected") {
          _this.$store
            .dispatch("LoginSystem/LOGINFB", {
              app: "mumu",
              access_token: userData.authResponse.accessToken,
              uuid: localStorage.getItem("uuid"),
            })
            .then((res) => {
              _this.$f7router.navigate("/home/");
            })
            .catch((err) => console.log(err));
        } else {
          _this.$f7.dialog.alert(res.data.stt || res.data.STT, "ລອງໃໝ່ອີກຄັ້ງ");
        }
      };
      facebookConnectPlugin.login(
        ["public_profile"],
        fbLoginSuccess,
        function loginError(error) {
          console.error(error);
        }
      );
    },
    loginapple: function () {
      if (localStorage.getItem("uuid") === null)
        localStorage.setItem("uuid", uuidv4());
      var _this = this;
      var apLoginSuccess = function (succ) {
        console.log(succ);
        _this.$store
          .dispatch("LoginSystem/LOGINAPPLE", {
            app: "mumu",
            access_token: succ.identityToken,
            uuid: localStorage.getItem("uuid"),
          })
          .then((res) => {
            _this.$f7router.navigate("/home/");
          })
          .catch((err) => console.log(err));
      };

      window.cordova.plugins.SignInWithApple.signin(
        { requestedScopes: [0, 1] },
        apLoginSuccess,
        function (err) {
          console.error(err);
          console.log(JSON.stringify(err));
          _this.$f7.dialog.alert(res.data.stt || res.data.STT, "ລອງໃໝ່ອີກຄັ້ງ");
        }
      );
    },
  },
};
</script>
<style scoped>
.icon-color {
  color: #ffa9ad;
}
.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}
@media screen and (min-width: 300px) {
  .fonts-menu-title-a {
    font-size: 19px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-name {
    font-size: 15px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 13px;
    color: #3c3c3c;
    font-weight: normal;
    font-family: boonhome;
  }
  img {
    width: 70px;
  }
}

@media screen and (min-width: 800px) {
  .fonts-menu-title-a {
    font-size: 25px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-name {
    font-size: 24px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #3c3c3c;
    font-weight: normal;
    font-family: boonhome;
  }
  img {
    width: 100px;
  }
}
</style>

