<template>
  <div class="page bg"  style="width: 100%">
    <f7-block strong >
      <div class="row">
        <div class="col-30 cs">
          <img
            style="border: 3px solid #555"
            class="center"
            src="https://cdn.framework7.io/placeholder/people-160x160-1.jpg"
          />
        </div>
        <div class="col-55" style="padding-left: 10px">
          <p class="fonts-menu-price" >id: {{ userinfo.uid }}</p>
          <p class="fonts-menu-price">ຊື່ຮ້ານ: {{ myshop.sn }}</p>
          <p class="fonts-menu-price">ຊື່ຜູ້ໃຊ້: {{ userinfo.fullname }}</p>
        </div>
        <!-- <div class="col-15">
          <f7-link href="/editShop/" panel-close>
            <i class="icon f7-icons icon-color" style="padding-top: 20px">square_pencil_fill</i>
          </f7-link>
        </div> -->
      </div>
    </f7-block>
    <f7-list menu-list class="fonts-menu-price">
      <f7-list-item panel-close link="/table/" title="ຫນ້າຫຼັກ">
        <template #media>
          <i class="icon f7-icons icon-color">house_fill</i>
        </template>
      </f7-list-item>
      <f7-list-item panel-close link="/manageList/" title="ຈັດການຂໍ້ມູນ">
        <template #media>
          <i class="icon f7-icons icon-color">tag_fill</i>
        </template>
      </f7-list-item>
      <!-- <f7-list-item panel-close link="/sale/" title="ຂາຍສິນຄ້າ">
        <template #media>
          <i class="icon f7-icons icon-color">cart</i>
        </template>
      </f7-list-item>-->
      <!-- <f7-list-item panel-close link="/billAll/" title="ໃບບິນທັງຫມົດ">
        <template #media>
          <i class="icon f7-icons icon-color">doc_fill</i>
        </template>
      </f7-list-item>-->
      <f7-list-item panel-close link="/reportsaleall/" title="ລາຍງານ">
        <template #media>
          <i class="icon f7-icons icon-color">square_favorites</i>
        </template>
      </f7-list-item>
      <f7-list-item panel-close link="/printerlabel/" title="ພິມບິນຝາກເຄືອງ">
        <template #media>
          <i class="icon f7-icons icon-color">printer</i>
        </template>
      </f7-list-item>
      <!-- <f7-list-item panel-close link="/package/" title="ແພັກເກດ">
        <template #media>
          <i class="icon f7-icons icon-color">square_favorites</i>
        </template>
      </f7-list-item> -->
    </f7-list>
    <f7-list menu-list class="fonts-menu-name">
      <!-- <f7-list-item panel-close link="/settinglist/" title="ການຕັ້ງຄ່າ">
        <template #media>
          <i class="icon f7-icons icon-color">wrench</i>
        </template>
      </f7-list-item> -->
      <f7-list-item panel-close @click="logout" link="/" title="ອອກຈາກລະບົບ">
        <template #media>
          <i class="icon f7-icons icon-color">arrow_right_circle_fill</i>
        </template>
      </f7-list-item>
    </f7-list>
  </div>
</template>
<script>
import { axios_app } from "@/js/axios-app";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    myshop: [],
    userinfo: [],
  }),
  computed: {
    ...mapGetters("LoginSystem", ["role"]),
    ...mapGetters("user", ["user_info"]),
    ...mapGetters("shop", ["shopinfo"]),
  },
  created() {
    this.$store.dispatch("shop/A_GET_SHOP");

    this.usershop();
    this.userinfor();
  },
  methods: {
    logout() {
      // var initialHref = window.location.href;
      this.$store.dispatch("LoginSystem/LOGOUT").then(() => {
        // this.$f7router.navigate("/");
        window.location = window.location.href;
      });
    },
    async usershop() {
      await axios_app({
        method: "GET",
        url: "/v1/mumu/usershop",
        data: {},
      })
        .then((resp) => {
          this.myshop = resp.data[0];
        })
        .catch((err) => {});
    },
    async userinfor() {
      await axios_app({
        method: "GET",
        url: "https://ma.mounoydev.com/v1/user/user-infomation",
        data: {},
      })
        .then((resp) => {
          this.userinfo = resp.data;
        })
        .catch((err) => {});
    },
  },
};
</script>
<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
img {
  border-radius: 50%;
  width: 75px;
}
.icon-color {
  color: #ffa9ad;
}

@media screen and (min-width: 300px) {
  .cs {
    padding-top: 13px;
    width: 50px;
  }
}

@media screen and (min-width: 700px) {
  .cs {
    padding-top: 25px;
    width: 75px;
  }
}
</style>
