<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <f7-link panel-open="#panel-nested">
            <a class="link">
              <i class="icon f7-icons icon-color">menu</i>
            </a>
          </f7-link>
        </div>
        <div class="title nb-title la">ເລືອກໂຕະ</div>
        <div class="right">
          <a href="#" class="link"></a>
        </div>
      </div>
    </div>
    <f7-panel id="panel-nested" left cover container-el="#panel-page">
      <Menu />
    </f7-panel>
    <!-- navbar -->

    <!-- navbar -->

    <!-- card -->
    <div class="page-content">
      <div class="row justify-content-center">
        <!-- {{shoptables}} -->
        <div v-for="d in showtables" :key="d.tid">
          <div>
            <f7-link @click="f7router.navigate('/menuchoose/'+d.tid+'/?tbname='+d.name)">
              <f7-card class="card-diy card-color-Activated center">
                <f7-card-content>
                  <f7-row >
                    <span class="fore-color">{{d.name}}</span>
                  </f7-row>
                </f7-card-content>
              </f7-card>
            </f7-link>
          </div>
        </div>

        <!-- <div class=" flex-direction-row xlarge-10 medium-25 small-30 xsmall-50" v-for="n in 10" :key="n">
          <f7-card class="card-diy card-color-notActivate center">
            <f7-link>
              <f7-card-content>
                <div>
                  <f7-row>
                    <span class="fore-color">A{{n}}</span>
                  </f7-row>
                </div>
              </f7-card-content>
            </f7-link>
          </f7-card>
        </div>-->
      </div>
    </div>

    <!-- card -->
    <!-- <f7-link @click="f7router.navigate('/about/')"> -->
    <!-- panel -->
  </div>
</template>
<script>
// import { axios_app } from "@/js/axios-app";
import Menu from "../components/menu.vue";
import { mapGetters } from "vuex";
export default {
    data: () => ({
   // mytables: [],
  }),
  components: { Menu },
  props: {
    f7router: Object,
  },
  created() {
    this.$store.dispatch("shop/GET_TABLE");
    this.$store.dispatch("shop/A_CATS");
    this.$store.dispatch("shop/A_MENU");
    this.$store.dispatch("shop/A_GET_SHOP");
    
  
   // this.mytables =  this.$store.state.tables
    //this.mytab();
    // console.log( this.$f7.views.main.router)
    // this.userinfor();
  },
  computed:mapGetters("shop",["showtables"]),//
  methods: {
    async mytab() {
      // await axios_app({
      //   method: "GET",
      //   url: "/v1/mumu/tables",
      //   data: {},
      // })
      //   .then((resp) => {
      //     this.mytables = resp.data;
      //   })
      //   .catch((err) => {});
    },
  },
};
</script>
<style scoped>
.container {
  display: flex; /* or inline-flex */
}
.fore-color {
  font-size: 20px;
  font-weight: bold;
  color: #ffff;
  font-family: boonhome;
  margin-top: 45px;
   margin-left: 45px;
}

.card-diy {
  height: 150px;
  width: 150px;
  background-color: #ffa9ad;
}
.card-color-Activated {
  background-color: #ffa9ad;
}
.card-color-notActivate {
  background-color: #ffe0e8;
}
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}
.logo img {
  border-radius: 100%;
}
.textfield {
  border: 2px solid #ffa9ad;
  border-radius: 5px;
}
.fonts-Bold {
  font-size: 36px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.fonts-Bold-button {
  font-size: 26px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}
.fonts-book {
  color: #ffa9ad;
  font-family: boonhome;
}
</style>