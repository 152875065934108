import { render, staticRenderFns } from "./register-shop.vue?vue&type=template&id=a36bd9f4&scoped=true&"
import script from "./register-shop.vue?vue&type=script&lang=js&"
export * from "./register-shop.vue?vue&type=script&lang=js&"
import style0 from "./register-shop.vue?vue&type=style&index=0&id=a36bd9f4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a36bd9f4",
  null
  
)

export default component.exports