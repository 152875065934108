<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
          </a>
        </div>
        <!-- navbar -->
        <div class="title nb-title la">
          ໂຕະ {{ this.$f7route.query.tbname }}
        </div>
        <div class="right">
          <a
            v-if="shopinfo.ordermenu == 1"
            class="link icon-color"
            :href="'/orders/' + this.$f7route.params.id"
          >
            <i class="icon f7-icons">square_list_fill</i>
          </a>
          <div class="right">
            <a
              v-if="shopinfo.ordermenu == 1"
              class="link icon-color"
              :href="'/menucheck/' + this.$f7route.params.id"
            >
              <i class="icon f7-icons">
                cart_fill
                <!-- <span class="badge color-red">5</span> -->
              </i>
            </a>
          </div>
          <div class="right">
            <a
              v-if="shopinfo.ordermenu == 0"
              class="link icon-color"
              :href="'/menucheck/' + this.$f7route.params.id"
            >
              <i class="icon f7-icons">
                cart_fill
                <!-- <span class="badge color-red">5</span> -->
              </i>
            </a>
          </div>
        </div>
        <div class="subnavbar">
          <div class="toolbar tabbar bg tabbar-scrollable toolbar-top">
            <div class="toolbar-inner">
              <a
                v-for="(d, index) in showcats"
                :key="d.cid"
                :href="'#tab-' + index"
                class="fonts-menu-price tab-link"
                :class="{ 'tab-link-active': index === 0 }"
                style="margin-left: 10px"
                >{{ d.name }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- navbar -->

    <!-- card -->
    <div class="tabs-swipeable-wrap">
      <div class="tabs">
        <div
          v-for="(c, i) in showcats"
          :key="i"
          :id="'tab-' + i"
          class="page-content tab"
        >
          <div class="row justify-content-center">
            <m-card
              v-for="d in showmenus[showcats[i].cid]"
              :key="d.proid"
              :tid="$f7route.params.id"
              :product="d"
            ></m-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { axios_app } from "@/js/axios-app";
// import Menu from "../components/menu.vue";
import cardmenu from "../components/cardmenu";
export default {
  components: {
    "m-card": cardmenu,
  },
  data: () => ({
    // mycat: [],
    // mypro: {},
    listcid: [],
    src: "https://www.itsallcoffee.com/wp-content/uploads/2020/03/171026-better-coffee-boost-se-329p_67dfb6820f7d3898b5486975903c2e51.fit-760w.jpg",
  }),
  // components: { Menu },
  // props: {
  //   f7router: Object,
  // },
  computed: mapGetters("shop", ["showmenus", "showcats", "shopinfo"]),
  created() {
    // this.probycat();
    // this.mytabcat();
  },
  methods: {
    // async mytabcat() {
    //   await axios_app({
    //     method: "GET",
    //     url: "/v1/mumu/cat",
    //     data: {},
    //   })
    //     .then(async (resp) => {
    //       this.mycat = resp.data;
    //     //   var mylist =[]
    //     //   resp.data.forEach(d=>{
    //     //     mylist.push(d.cid)
    //     //   })
    //     // this.listcid =  mylist ;
    //     //   console.log(this.listcid)
    //       })
    // },
    // async probycat() {
    //  await axios_app({
    //     method: "GET",
    //     url: "/v1/mumu/pro/",
    //     data: {},
    //   })
    //     .then( (resp) => {
    //       var tem=resp.data;
    //     var outObject = tem.reduce(function(a, e) {
    //       // GROUP BY estimated key (estKey), well, may be a just plain key
    //       // a -- Accumulator result object
    //       // e -- sequentally checked Element, the Element that is tested just at this itaration
    //       // new grouping name may be calculated, but must be based on real value of real field
    //       let estKey = (e['cid']);
    //       (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
    //       return a;
    //     }, {});
    //     console.log(outObject);
    //     this.mypro = outObject;
    //     })
    //     .catch((err) => {});
    // },
  },
};
</script>
<style scoped>
.bg {
  background-color: white;
}
.icon-color {
  color: #ffa9ad;
}

.center {
  align-items: center;
  text-align: center;
}
@media screen and (min-width: 300px) {
  .fonts-menu-name {
    font-size: 17px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 14px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-a {
    font-size: 18px;

    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-b {
    font-size: 20px;

    font-weight: bold;
    font-family: boonhome;
  }
  img {
    width: 100px;
  }
}

@media screen and (min-width: 800px) {
  .fonts-menu-name {
    font-size: 24px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-a {
    font-size: 20px;

    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-b {
    font-size: 23px;

    font-weight: bold;
    font-family: boonhome;
  }
  img {
    width: 150px;
  }
}

.my-card {
  width: 280px;
  height: 220px;
  margin: 10px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}
div.absolute {
  position: absolute;
  width: 100%;
  bottom: 0px;
  background-color: rgba(51, 49, 49, 0.582) !important;
}
.icon-color {
  color: #ffa9ad;
}
.center {
  align-items: center;
  text-align: center;
}
</style>



