import Axios from "axios";
//import jwt_decode from "jwt-decode";
var n = require('nonce')();
import LocalStorageService from "@/js/localStorage";
const localStorageService = LocalStorageService.getService();

var api_authen = "https://ma.mounoydev.com";
const axiosAPIistance = Axios.create({
  baseURL: api_authen,
});
axiosAPIistance.defaults.timeout = 60 * 1000 * 5;

axiosAPIistance.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers["authorization"] = token;
      config.headers["nonce"] = n();
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosAPIistance.interceptors.response.use(
  (response) => {
    return response;
  },
  function(error) {
    const originalRequest = error.config;
    
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorageService.getRefreshToken();
      return axiosAPIistance({
        method: "POST",
        url: "https://ma.mounoydev.com/v1/login/renew",
        data: {
          app: "mumu",
          uuid: localStorage.getItem("uuid"),
          rftk: refreshToken,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            localStorageService.setToken(res.data);
            axiosAPIistance.defaults.headers.common[
              "authorization"
            ] = localStorageService.getAccessToken();
            return axiosAPIistance(originalRequest);
          }
        })
        .catch((err) => {
          if (err.response.status == 403) this.$f7router.navigate("/login/");
        });
    }
    return Promise.reject(error);
  }
);

export const axios_authen = axiosAPIistance;
