<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
          </a>
        </div>

        <div class="title nb-title">ຈັດການໂຕະ</div>
        <div class="right">
          <a href="#" class="link icon-color" @click="madd">
           
            <i class="icon f7-icons">plus_circle</i>
          </a>
        </div>
      </div>
    </div>
    <br />
    <div class="page-content">
      <!-- <div class="block-title">Swipe for actions</div> -->
      <div class="list">
        <ul v-for="(d, i) in data" :key="d">
          <li class="swipeout">
            <div class="item-content swipeout-content">
              <div class="item-media">
                <i class="icon icon-f7"></i>
              </div>
              <div class="item-inner">
                <div class="fonts-menu-price item-title la">
                  <i class="icon f7-icons icon-color">layers_alt_fill</i>
                  ໂຕະທີ່ {{ d.name }}
                </div>
              </div>
            </div>
            <div class="swipeout-actions-right">
              <a href="#" @click="medit(d.tid, d.name)">Edit</a>
              <a @click="mdelete(d.tid, d.name)" class="swipeout-close delred"
                >Delete</a
              >
              <!-- <a @click="mdelete(d.cid,d.name)" class="swipeout-delete">Delete</a> -->
            </div>
          </li>
        </ul>
      </div>
      <!-- <div class="block list-title">ທັງຫມົດ: 10​ ປະເພດ</div> -->
      <!-- <div class="list inset media-list">
        <ul>
          <div v-for="d in data" :key="d">
            <li class="swipeout">
              <a href="#" class="item-content">
                <div class="item-media">
                  <img src="https://cdn.framework7.io/placeholder/people-160x160-1.jpg" width="40" />
                </div>
                <div class="item-inner">
                  <div class="item-title-row ">
                    <div class="item-title fonts-menu-name">{{d.name}}</div>
                  </div>
                  <div class="item-subtitle fore-item">-</div>
                </div>
              </a>
            </li>
          </div>  
        </ul>
      </div>-->
    </div>
  </div>
</template>
<script>
import { axios_app } from "@/js/axios-app";

export default {
  data() {
    return {
      data: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init: function () {
      axios_app({
        method: "GET",
        url: "/v1/mumu/tables",
        data: {},
      })
        .then((resp) => {
          this.data = resp.data;
        })
        .catch((err) => {
          // reject(err);
        });
    },
    mdelete: function (id, name) {
      var _this = this;
      this.$f7.dialog.confirm(
        "Are you sure to Delete " + name + "?",
        function () {
          axios_app({
            method: "DELETE",
            url: "/v1/mumu/tables/" + id,
            data: {},
          })
            .then((resp) => {
              _this.init();
              // this.data = resp.data;
            })
            .catch((err) => {
              // reject(err);
            });
          //   this.$f7.dialog.alert("Ok, your name is " + name);
        }
      );
    },
    medit: function (id, old_name) {
      var _this = this;
      this.$f7.dialog.prompt("ກະລຸນາປ່ຽນຊຶ່ປະເພດ", function (name) {
        axios_app({
          method: "PATCH",
          url: "/v1/mumu/tables/" + id,
          data: { name: name },
        })
          .then((resp) => {
            _this.init();
          })
          .catch((err) => {
            // reject(err);
          });
      });
    },
    madd: function () {
      var _this = this;
      this.$f7.dialog.prompt("ໂຕະທີ່", function (name) {
        axios_app({
          method: "POST",
          url: "/v1/mumu/tables",
          data: { name: name },
        }).then((resp) => {
          _this.init();
        });
      });
    },
  },
};
</script>
<style scoped>
.delred {
  color: red;
}
.fore-item {
  font-size: 20px;
  color: #878484;
  font-family: boonhome;
}

.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}

.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}
.fonts-book {
  color: #ffa9ad;
  font-family: boonhome;
}
@media screen and (min-width: 300px) {
  .fonts-menu-name {
    font-size: 15px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 13px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  img {
    width: 70px;
  }
}

@media screen and (min-width: 800px) {
  .fonts-menu-name {
    font-size: 24px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  img {
    width: 100px;
  }
}
</style>

