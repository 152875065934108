<template>
  <div class="page no-navbar no-toolbar no-swipeback">
    <div class="page-content login-screen-content">
      <br />
      <div class="logo img center">
        <img src="static/icons/512x512.png" class="logo" width="20%" />
      </div>
      <div class="login-screen-title fonts-Bold shadow">Mumu POS</div>

      <form>
        <div class="list no-hairlines-between">
          <ul>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="text" v-model="email" name="username" placeholder="ປ້ອນອີເມວ" />
                </div>
              </div>
            </li>

            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="password" v-model="password" name="password" placeholder="ປ້ອນລະຫັດຜ່ານ" />
                </div>
              </div>
            </li>
            <li>
              <f7-row>
                <f7-col>
                  <label
                    class="item-checkbox item-content"
                    style="font-family: boonhome; padding-top:10px;"
                  >
                    <input type="checkbox" name="demo-checkbox" value="Books" checked="checked" />
                    <i class="icon icon-checkbox"></i>
                    <div class="item-inner">
                      <div class="item-title">ຈື່ຂ້ອຍໄວ້</div>
                    </div>
                  </label>
                </f7-col>
                <f7-col>
                  <label
                    class="item-checkbox item-content justify-content-right"
                    style="font-family: boonhome; padding-left:60px; "
                  >
                    <div class="center" style="padding-top:5px">
                      <p style="font-family: boonhome">
                        <a
                          href="/forgetpass/"
                          style="font-family: boonhome; font-weight: bold;"
                        >ລືມລະຫັດຜ່ານ?</a>
                      </p>
                    </div>
                  </label>
                </f7-col>
              </f7-row>
            </li>
          </ul>
        </div>

        <div class="block">
          <div class="button-main">
            <f7-button raised fill @click="login"> ເຂົ້າສູ່ລະບົບ</f7-button>
          </div>
          <div class="center">
            <p style="font-family: boonhome">
              ຜູ້ໃຊ້ໃຫມ່?
              <a
                href="/registerUser/"
                style="font-family: boonhome; font-weight: bold;"
              >ສ້າງບັນຊີຜູ້ໃຊ້</a>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
export default {
  data() {
    return {
      jobIsDone: false,
      email:"",
      password:""
    };
  },
  methods: {
    login: function () {
      if (localStorage.getItem("uuid") === null)
        localStorage.setItem("uuid", uuidv4());
      if (!this.email || !this.password)
        return this.$f7.dialog.alert("ກະລຸນາໃສ່ຂໍ້ມູນ", "ລອງໃໝ່ອີກຄັ້ງ");
      if (this.password.length < 6)
        return this.$f7.dialog.alert(
          "Email or password not match",
          "ລອງໃໝ່ອີກຄັ້ງ"
        );

      this.$store
        .dispatch("LoginSystem/LOGIN", {
          app: "mumu",
          email: this.email,
          password: this.password,
          uuid: localStorage.getItem("uuid"),
        })
        .then((res) => {
          if (res.status === 201) {

            // setTimeout(() => {
            //   this.$f7router.navigate("/table/");
            // }, 500);
          

            this.$f7router.navigate("/table/");

          }
          if (res.status === 200) {
            this.$f7.dialog.alert(
              res.data.stt || res.data.STT,
              "ລອງໃໝ່ອີກຄັ້ງ"
            );
          }
        })
        .catch((err) => console.log(err));

  
    },
  }
};
</script>
<style scoped>
.center {
  align-items: center;
  text-align: center;
}
.logo img {
  border-radius: 100%;
}

.fonts-Bold {
  font-size: 36px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.fonts-Bold-button {
  font-weight: bold;
  font-family: boonhome;
}
.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}
</style>