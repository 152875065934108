const LocalStorageService = (function() {
  var _service;
  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }
  function _setToken(tokenObj) {
    localStorage.setItem("actk", tokenObj.actk);
    localStorage.setItem("rftk", tokenObj.rftk);
  }
  function _getAccessToken() {
    return localStorage.getItem("actk");
  }
  function _getRefreshToken() {
    return localStorage.getItem("rftk");
  }
  function _clearToken() {
    console.log("_clearToken");
    localStorage.removeItem("actk");
    localStorage.removeItem("rftk");
  }
  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
  };
})();
export default LocalStorageService;
