<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Back</span>
          </a>
        </div>
        <div class="title nb-title">ສິນຄ້າຂາຍດີ</div>
        <div class="right">
          <a href="#" class="link"></a>
        </div>
      </div>
    </div>
    <!-- doc_plaintext -->
    <!-- navbar -->

    <!-- navbar -->
    <!-- content -->
    <div class="page-content">
      <div class="list inset">
        <ul>
          <div v-for="n in 10" :key="n">
            <li>
              <a href="#" class="item-content swipeout-content item-content" style=" margin:20px">
                <div class="item-media">
                  <i class="icon icon-f7"></i>
                </div>
                <div class="item-inner item-style">
                  <div class="item-title">{{n}}. Americano</div>

                  <div class="item-after">ຈຳນວນ</div>
                </div>
              </a>
            </li>
          </div>
        </ul>
      </div>
    </div>
    <!-- content -->
  </div>
</template>
<script>
import Menu from "../components/menu.vue";

export default {
  components: { Menu },
  props: {
    f7router: Object,
  },
};
</script>
<style scoped>
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}

@media screen and (min-width: 300px) {
  .fonts-menu-title-a {
    font-size: 19px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-name {
    font-size: 15px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 13px;
    color: #3c3c3c;
    font-weight: normal;
    font-family: boonhome;
  }
  img {
    width: 70px;
  }
}

@media screen and (min-width: 800px) {
  .fonts-menu-title-a {
    font-size: 25px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-name {
    font-size: 24px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #3c3c3c;
    font-weight: normal;
    font-family: boonhome;
  }
  img {
    width: 100px;
  }
}
</style>

