<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
          </a>
        </div>
        <div class="title nb-title">ພິມໃບບິນຂົນສົ່ງ</div>
        <div class="right">
          <a class="link icon-color">
            <i @click="pcprintbill" class="icon f7-icons">printer</i>
          </a>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="block list-title justify-content-space-between">
        <f7-row>
            <div class="item-inner">
              <div class="item-title item-label">ຊື່ລູກຄ້າ</div>
              <div class="item-input-wrap">
                <input type="text"  v-model="cname" placeholder="ປ່ອນຊື່ລູກຄ້າ" />
                <span class="input-clear-button"></span>
              </div>
            </div>
        </f7-row>
             <f7-row>
            <div class="item-inner">
              <div class="item-title item-label">ເບີ</div>
              <div class="item-input-wrap">
                <input type="text"  v-model="ctel" placeholder="ປ່ອນເບີລູກຄ້າ" />
                <span class="input-clear-button"></span>
              </div>
            </div>
        </f7-row>
        <f7-row>
            <div class="item-inner">
              <div class="item-title item-label">ບ້ານ</div>
              <div class="item-input-wrap">
                <input type="text"  v-model="vname" placeholder="ປ່ອນບ້ານ" />
                <span class="input-clear-button"></span>
              </div>
            </div>
        </f7-row>
          <f7-row>
            <div class="item-inner">
              <div class="item-title item-label">ເມືອງ</div>
              <div class="item-input-wrap">
                <input type="text"  v-model="dname" placeholder="ປ່ອນເມືອງ" />
                <span class="input-clear-button"></span>
              </div>
            </div>
        </f7-row>
          <f7-row>
            <div class="item-inner">
              <div class="item-title item-label">ແຂວງ</div>
              <div class="item-input-wrap">
                <input type="text"  v-model="pname" placeholder="ປ່ອນແຂວງ" />
                <span class="input-clear-button"></span>
              </div>
            </div>
        </f7-row>
                  <f7-row>
            <div class="item-inner">
              <div class="item-title item-label">ຝາກຂົນສົ່ງ</div>
              <div class="item-input-wrap">
                <input type="text"  v-model="ddname" placeholder="ປ່ອນຂົນສົ່ງ" />
                <span class="input-clear-button"></span>
              </div>
            </div>
        </f7-row>
      </div>
        <div style="display: none">
          <div id="bill">
            <div class="printing">
              <div class="invoice-title">
                <img
                  v-if="shopinfo.logo != '' || shopinfo.logo != null"
                  :src="shopinfo.logo"
                  style="width: 3cm"
                />
                <br />
                <span class="fl12">ວັນທີ: {{ this.mydate }}</span>
                <!-- <br /> -->
                <!-- <span class="fl12">ເບີໂທ:{{ shopinfo.tel }}</span> -->
                <!-- <br /> -->
                <!-- <span class="fontlao fl12">ສາຂາ:{{brname}}</span> -->
              </div>
        
                <div>
               <span class="fl12">ຊື່ລູກຄ້າ: {{this.cname}}</span>
                </div>
                  <div>
                  <span class="fl12">ເບີ: {{this.ctel}}</span>  
                  </div>
                      <div>
                  <span class="fl12">ບ້ານ: {{this.vname}}</span>  
                  </div>
            <div>
               <span class="fl12">ເມືອງ: {{this.dname}}</span>
            </div>
        <div>
              <span class="fl12">ແຂວງ: {{this.pname}}</span>
        </div>
             <div>
              <span class="fl12">ຝາກຂົນສົ່ງ: {{this.ddname}}</span>
        </div>
            
          
              <div>
 
             
              </div>
            </div>
            <div class="center">
              <span class="fl12"> ຂອບໃຈທີ່ອຸດໜູນ</span>
            </div>
          </div>
        </div>
      </div>
   
  </div>
</template>
<script>
// import Menu from "../components/menu.vue";
import { mapGetters } from "vuex";
import eventHub from "../js/eventHub";
import Printd from "printd";
// import * as htmlToImage from "html-to-image";
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

export default {
  computed: {
    ...mapGetters("shop", ["getsendorder", "bill", "shopinfo"]),
    countmenu: function () {
      let a = this.$store.state.bill.list;
      return Object.keys(a).length;
    },
    // countmenu:function(){
    //   let a =this.showorders;
    //  return Object.keys(a).length;
    // }
  },
  // components: { Menu },
  data() {
    return {
      cname: null,
      vname: null,
      dname: null,
      pname: null,
      ddname:null,
      ctel:null,
      mydate: new Date().toISOString().slice(0, 10),
    };
  },
  created() {
    // this.sendodersbill();
  },
  methods: {
    pcprintbill() {
      const cssText = `
      @font-face {
  font-family: boonhome;
  src: url(../fonts/BoonHome-400.ttf);
  font-weight: normal;
  font-style: normal;

}
      *{ 
        font-family: boonhome !important; 
      }
      .fl12{
        font-size:12px;
      }
      .priceqt{
        width:20mm;
      }
      .amount{
        width:18mm;
        text-align: right  !important;
      }
      `;
      var d = new Printd();
      d.print(document.getElementById("bill"), [cssText]);
    },
  },
};
</script>
<style scoped>
.icon-color {
  color: #ffa9ad;
}
.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}
@media screen and (min-width: 300px) {
  .fonts-menu-title-a {
    font-size: 19px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-name {
    font-size: 15px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 13px;
    color: #3c3c3c;
    font-weight: normal;
    font-family: boonhome;
  }
  img {
    width: 70px;
  }
}

@media screen and (min-width: 800px) {
  .fonts-menu-title-a {
    font-size: 25px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-name {
    font-size: 24px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #3c3c3c;
    font-weight: normal;
    font-family: boonhome;
  }
  img {
    width: 100px;
  }
}
</style>

