<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
          </a>
        </div>

        <!-- navbar -->

        <div class="title nb-title">ລາຍງານ</div>
        <div class="right">
          <a href="#" class="link icon-color">
            <i class="icon f7-icons"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- navbar -->

    <!-- card -->

    <div class="page-content">
      <div class="block" style="margin-left:10px">
        <f7-card class="card-diy card-color-Activated center">
          <div class="row">
            <div class="col-30" style="padding-top:10px">
              <i class="icon f7-icons img">cart_fill</i>
            </div>
            <div class="col-70">
              <a href="/reportsaleall/">
                <div class="row">
                  <span class="fonts-menu-price center">ຍອດຂາຍປະຈຳເດືອນ</span>
                </div>
                <div class="row">
                  <div class="col resizable">
                    <hr style="height:2px;border-width:0;color:white;background-color:white" />
                  </div>
                </div>
                <div class="row">
                  <span class="fonts-menu-name">1.000.000k | 50 ບິນ</span>
                </div>
              </a>
            </div>
          </div>
        </f7-card>
        <f7-card class="card-diy card-color-Activated center">
          <div class="row">
            <div class="col-30" style="padding-top:10px">
              <i class="icon f7-icons img">layers_alt</i>
            </div>
            <div class="col-70">
              <div class="row">
                <span class="fonts-menu-price-a center">ປະເພດສິນຄ້າທີ່ຂາຍດີປະຈຳເດືອນ</span>
              </div>
              <div class="row">
                <div class="col resizable">
                  <hr style="height:2px;border-width:0;color:white;background-color:white" />
                </div>
              </div>
              <div class="row">
                <span class="fonts-menu-name">ເຄື່ອງດື່ມ | 50 ບິນ</span>
              </div>
            </div>
          </div>
        </f7-card>
        <!-- <f7-card class="card-diy card-color-Activated center">
          <div class="row">
            <div class="col-30" style="padding-top:10px">
              <i class="icon f7-icons img">line_horizontal_3_decrease</i>
            </div>
            <div class="col-70">
              <a href="/reportbestsale/">
                <div class="row">
                  <span class="fonts-menu-price center">ສິນຄ້າຂາຍດີ</span>
                </div>
                <div class="row">
                  <div class="col resizable">
                    <hr style="height:2px;border-width:0;color:white;background-color:white" />
                  </div>
                </div>
                <div class="row">
                  <span class="fonts-menu-name">10 ອັນດັບ</span>
                </div>
              </a>
            </div>
          </div>
        </f7-card> -->
      </div>
    </div>
  </div>
</template>
<script>
import Menu from "../components/menu.vue";

export default {
  components: { Menu },
};
</script>
<style scoped>
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}

.textfield-round {
  border: 2px solid #ffa9ad;
  border-radius: 100px;
  padding: 20px;
}
.card-diy {
  width: 90%;
  background-color: #ffa9ad;
}

.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}

@media screen and (min-width: 300px) {
  .fonts-menu-name {
    font-size: 20px;
    color: #ffff;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #ffff;
    font-weight: normal;
    font-family: boonhome;
  }
  .fonts-menu-price-a {
    font-size: 16px;
    color: #ffff;
    font-weight: normal;
    font-family: boonhome;
  }
  .img {
    font-size: 60px;
    color: #ffff;
  }
}

@media screen and (min-width: 700px) {
  .fonts-menu-name {
    font-size: 35px;
    color: #ffff;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price-a {
    font-size: 35px;
    color: #ffff;
    font-weight: normal;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 35px;
    color: #ffff;
    font-weight: normal;
    font-family: boonhome;
  }
  .img {
    font-size: 100px;
  }
}
</style>

