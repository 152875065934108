<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
          </a>
        </div>

        <!-- navbar -->

        <div class="title fonts-Bold-button">ເພີ່ມສິນຄ້າໃຫມ່</div>
        <div class="right">
          <a @click="padd" class="fonts-book">ບັນທຶກ</a>
        </div>
      </div>
    </div>
    <br />
    <!-- navbar -->
    <br />

    <div class="login-screen-content">
      <form>
        <div class="list no-hairlines-between">
          <ul>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="text" v-model="pname" placeholder="ຊື່ສິນຄ້າ" />
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap input-dropdown-wrap textfield">
                  <select v-model="catselected" placeholder="Please choose...">
                    <option
                      v-for="d in catdata"
                      :key="d.cid"
                      v-bind:value="d.cid"
                    >
                      {{ d.name }}
                    </option>
                  </select>
                </div>
              </div>
            </li>

            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="number" v-model="price" placeholder="ລາຄາຂາຍ" />
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input
                    type="text"
                    @keydown="keysevent"
                    v-model="barcode"
                    placeholder="Barcode"
                  />
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  @change="handleImageUpload"
                />
              </div>
            </li>
            <li>
              <div
                class="center"
                style="border-style: inset; border-color: #ffa9ad"
              >
                <img class="img-pro" :src="preview" alt="No pic" />
              </div>
            </li>
          </ul>
        </div>
      </form>
    </div>
    <!-- card -->
  </div>
</template>
<script>
import { axios_app } from "@/js/axios-app";

import imageCompression from "browser-image-compression";
export default {
  props: {
    product: Object,
  },
  data() {
    return {
      files: null,
      preview: null,
      catdata: null,
      catselected: null,
      price: null,
      barcode: null,
      pname: null,
      imageResize: null,
      isEdit: null,
      proid: null,
      choosephoto: false,
    };
  },
  created() {},
  mounted() {
    this.initcat();
  },
  methods: {
    keysevent(e) {
      var value = e.target.value;
      //only allow a-z, A-Z, digits 0-9 and comma, with only 1 consecutive comma ...
      if (!e.key.match(/[a-zA-Z0-9]/) || e.key == ",") {
        e.preventDefault();
      }
    },
    initcat: function () {
      axios_app({
        method: "GET",
        url: "/v1/mumu/cat",
        data: {},
      })
        .then((resp) => {
          this.catdata = resp.data;
          this.catselected = this.catdata[0].cid;
          if (this.$f7route.params.id != 0) {
            this.proid = this.product.proid;
            this.isEdit = true;
            this.catselected = this.product.cid;
            console.log(this.catselected);
            this.pname = this.product.name;
            this.barcode = this.product.barcode;
            this.price = this.product.price;
            console.log(this.product);
            if (this.product.pic != null) {
              this.preview =
                "https://ms.mounoydev.com/mumu/" + this.product.pic;
            }
          }
          // this.catselected = this.catdata.length ? this.catdata[0].cid : "";
        })
        .catch((err) => {
          // reject(err);
        });
    },

    padd: async function () {
      console.log("Edit" + this.isEdit);
      var pic = null;
      if (this.isEdit == true) {
        console.log("access edit");
        if (this.choosephoto == true) {
          await axios_app({
            method: "POST",
            url: "https://ms.mounoydev.com/remove",
            data: {
              name: this.product.pic,
            },
          })
            .then((resp) => {
              console.log("delete from store");
            })
            .catch((err) => {
              // reject(err);
            });
          await axios_app({
            method: "POST",
            url: "https://ms.mounoydev.com/upload64",
            data: {
              data64: this.preview,
            },
          }).then((resp) => {
            console.log("pic:" + resp.data);
            pic = resp.data;
          });
        } else if (this.choosephoto == false && this.preview != null) {
          pic = this.preview.split("https://ms.mounoydev.com/mumu/")[1];
        }

        console.log(pic);
        await axios_app({
          method: "PUT",
          url: "/v1/mumu/pro/" + this.proid,
          data: {
            name: this.pname,
            cid: this.catselected,
            price: this.price,
            barcode: this.barcode,
            isset: "0",
            pic: pic,
          },
        }).then((result) => {
          this.$f7router.back("/allItem/", { force: true });
        });
      } else {
        console.log("access insert");
        if (this.preview != null) {
          console.log("access insert image");
          axios_app({
            method: "POST",
            url: "https://ms.mounoydev.com/upload64",
            data: {
              data64: this.preview,
            },
          })
            .then((resp) => {
              axios_app({
                method: "POST",
                url: "/v1/mumu/pro",
                data: {
                  name: this.pname,
                  cid: this.catselected,
                  price: this.price,
                  barcode: this.barcode,
                  isset: "0",
                  pic: resp.data,
                },
              }).then((result) => {
                this.$f7router.back("/allItem/", { force: true });
              });
            })
            .catch((err) => {});
        } else if (this.preview == null) {
          console.log("access insert no image");
          axios_app({
            method: "POST",
            url: "/v1/mumu/pro",
            data: {
              name: this.pname,
              cid: this.catselected,
              price: this.price,
              barcode: this.barcode,
              isset: "0",
              pic: null,
            },
          }).then((result) => {
            this.$f7router.back("/allItem/", { force: true });
          });
        }
      }
    },

    async handleImageUpload(event) {
      const imageFile = event.target.files[0];
      console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
      console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
      var options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1500,
        useWebWorker: false,
      };

      try {
        const compressedFile = await imageCompression(imageFile, options);

        var reader = new FileReader();
        reader.onload = (e) => {
          this.choosephoto = true;
          this.preview = e.target.result;
        };
        reader.readAsDataURL(compressedFile); //Call funstion
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.img-pro {
  margin: 10px;
  width: 300px;
}
.fore-item {
  font-size: 20px;
  color: #878484;
  font-family: boonhome;
}

.card-diy {
  height: 150px;
  width: 150px;
  background-color: #ffa9ad;
}
.card-color {
  background-color: #ffa9ad;
}
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}
.logo img {
  border-radius: 100%;
}
.textfield {
  border: 2px solid #ffa9ad;
  border-radius: 5px;
}
.textfield-round {
  border: 2px solid #ffa9ad;
  border-radius: 100px;
  padding: 20px;
}
.fonts-Bold {
  font-size: 36px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.fonts-Bold-button {
  font-size: 26px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}
.fonts-book {
  color: #ffa9ad;
  font-family: boonhome;
}
@media screen and (min-width: 400px) {
  .fonts-menu-name {
    font-size: 18px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 14px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
}

@media screen and (min-width: 800px) {
  .fonts-menu-name {
    font-size: 24px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
}
</style>

