<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Back</span>
          </a>
        </div>
        <div class="title nb-title">ຕັ້ງຄ່າ</div>
        <div class="right">
          <a href="#" class="link"></a>
        </div>
      </div>
    </div>

    <!-- navbar -->

    <!-- navbar -->

    <!-- card -->
    <div class="page-content">
      <div class="list inset list-title">
        <ul>
          <li>
            <a href="/editProfile/" class="item-link item-content swipeout-content item-content">
              <div class="item-media">
                <i class="icon f7-icons icon-color">person_alt</i>
              </div>
              <div class="item-inner">
                <div class="item-title fonts-book">ຂໍ້ມູນພະນັກງານ</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/editShop/" class="item-link item-content swipeout-content item-content">
              <div class="item-media">
                <i class="icon f7-icons icon-color">house_alt</i>
              </div>
              <div class="item-inner">
                <div class="item-title fonts-book">ຂໍ້ມູນຮ້ານ</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/editCurrency/" class="item-link item-content swipeout-content item-content">
              <div class="item-media">
                <i class="icon f7-icons icon-color">money_dollar_circle</i>
              </div>
              <div class="item-inner">
                <div class="item-title fonts-book">ອັດຕາແລກປ່ຽນ</div>
              </div>
            </a>
          </li>
          <li>
            <a href="/ListPrinter/" class="item-link item-content swipeout-content item-content">
              <div class="item-media">
                <i class="icon f7-icons icon-color">printer_fill</i>
              </div>
              <div class="item-inner">
                <div class="item-title fonts-book">ຕັ້ງຄ່າປຣິ້ນເຕີ້</div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- card -->
  </div>
</template>
<script>
import Menu from "../components/menu.vue";

export default {
  components: { Menu },
  props: {
    f7router: Object,
  },
};
</script>
<style scoped>
.fore-color-card {
  font-size: 20px;
  color: #ffff;
  font-family: boonhome;
}

.card-diy {
  height: 150px;
  width: 150px;
  background-color: #ffa9ad;
}
.card-color {
  background-color: #ffa9ad;
}
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}
.logo img {
  border-radius: 100%;
}
.textfield {
  border: 2px solid #ffa9ad;
  border-radius: 5px;
}
.fonts-Bold {
  font-size: 36px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.fonts-Bold-button {
  font-size: 26px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}
.fonts-book {
  color: #878484;
  font-family: boonhome;
}
</style>

