<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
          </a>
        </div>

        <!-- navbar -->

        <div class="title nb-title">ລາຍການອາຫານ</div>
        <div class="right">
          <a href="/checkbill/" class="link icon-color">
            <i class="icon f7-icons">money_dollar_circle</i>
          </a>
        </div>
      </div>
    </div>
    <!-- navbar -->

    <!-- card -->

    <div class="page-content">
      <f7-block>
        <div class="list no-hairlines-between">
          <ul>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="text" name="username" placeholder="ປ້ອນ Barcode" />
                </div>
              </div>
              <a class="link icon-color">
                <i class="icon f7-icons" style="font-size: 40px;">barcode_viewfinder</i>
              </a>
            </li>
          </ul>
        </div>
      </f7-block>
      <!-- <div class="list no-hairlines-between">
        <ul>
          <li class="item-content item-input">
            <f7-row>
              <f7-col width="75">
                
              </f7-col>
              <f7-col width="25">
                <button>1</button>
              </f7-col>
            </f7-row>
          </li>
        </ul>
      </div>-->

      <div class="block list-title justify-content-space-between">
        <f7-row>
          <span class="fonts-menu-title-a">ລາຍການທັງຫມົດ: 10</span>
          <span class="fonts-menu-title-a">ລວມ: 100.000 K</span>
        </f7-row>
      </div>
      <div class="list">
        <ul>
          <div v-for="n in 10" :key="n">
            <li class="swipeout">
              <a href="#" class="item-content swipeout-content item-content">
                <div class="item-media">
                  <i class="icon icon-f7"></i>
                </div>
                <div class="item-inner item-style">
                  <div class="item-title fonts-menu-name">Americano</div>
                  <div class="item-after">
                    <div class="stepper stepper-round stepper-fill" style="padding: 10px;">
                      <div class="stepper-button-minus"></div>
                      <div class="stepper-input-wrap">
                        <input type="text" value="1" min="0" max="100" step="1" readonly />
                      </div>
                      <div class="stepper-button-plus"></div>
                    </div>
                  </div>
                  <div class="item-after fonts-menu-name">10.000 ₭</div>
                </div>
                <a
                  href="#"
                  data-confirm="Are you sure you want to delete this item?"
                  class="swipeout-delete"
                >
                  <i class="f7-icons">xmark</i>
                </a>
              </a>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Menu from "../components/menu.vue";

export default {
  components: { Menu },
};
</script>
<style scoped>
.icon-color {
  color: #ffa9ad;
}
.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}
.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}
.fonts-book {
  color: #ffa9ad;
  font-family: boonhome;
}
@media screen and (min-width: 300px) {
  .fonts-menu-title-a {
    font-size: 19px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-name {
    font-size: 15px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 13px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
}

@media screen and (min-width: 700px) {
  .fonts-menu-title-a {
    font-size: 25px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-name {
    font-size: 24px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
}
</style>

