import Axios from "axios";
// import vue from "@/js/app";
import jwt_decode from "jwt-decode";
import LocalStorageService from "@/js/localStorage";
const localStorageService = LocalStorageService.getService();

export default {
  namespaced: true,
  state: {
    status: "",
    role: "",
    token: localStorageService.getAccessToken() || "",
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token) {
      state.status = "success";
      state.token = token;
      var role = jwt_decode(token).role;
      state.role = role;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
  },
  actions: {
    REGISTER({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        Axios({
          method: "POST",
          url: "https://ma.mounoydev.com/v1/register/email",
          data: user,
        })
          .then(async (resp) => {
            // if (resp.status === 201) {
            //   let token = await resp.data.token;
            //   await localStorageService.setToken(token);
            //   await commit("auth_success", token);
            // }
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorageService.clearToken();
            reject(err);
          });
      });
    },
    LOGIN({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        Axios({
          method: "POST",
          url: "https://ma.mounoydev.com/v1/login/email",
          data: user,
        })
          .then(async (resp) => {
            if (resp.status === 201) {
              let token = await resp.data;
              await localStorageService.setToken(token);
              await commit("auth_success", token.actk);
            }
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorageService.clearToken();
            reject(err);
          });
      });
    },
    LOGINFB({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        Axios({
          method: "POST",
          url: "https://ma.mounoydev.com/v1/login/fb",
          data: user,
        })
          .then(async (resp) => {
            let token = await resp.data;
            await localStorageService.setToken(token);
            await commit("auth_success", token.actk);

            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorageService.clearToken();
            reject(err);
          });
      });
    },
    LOGINAPPLE({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        Axios({
          method: "POST",
          url: "https://ma.mounoydev.com/v1/login/ap",
          data: user,
        })
          .then(async (resp) => {
            let token = await resp.data;
            await localStorageService.setToken(token);
            await commit("auth_success", token.actk);

            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorageService.clearToken();
            reject(err);
          });
      });
    },
    LOGOUT({ commit }) {
      return new Promise((resolve, reject) => {
        // var that = vue;
        commit("logout");
        localStorageService.clearToken();
        // that.$f7.views.main.router.navigate("/");
        resolve();
      });
    },
    LOGIN_GUEST({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        Axios({
          method: "POST",
          url: "https://ma.mounoydev.com/v1/login/guest ",
          data: user,
        })
          .then(async (resp) => {
            let token = await resp.data;
            await localStorageService.setToken(token);
            await commit("auth_success", token.actk);
            await resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorageService.clearToken();
            reject(err);
          });
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !state.token,
    authStatus: (state) => state.status,
    role: (state) => state.role,
  },
};
