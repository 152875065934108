import Axios from "axios";
//import jwt_decode from "jwt-decode";
import Store from "../js/store";
var n = require("nonce")();
import LocalStorageService from "@/js/localStorage";
const localStorageService = LocalStorageService.getService();
// var api_app = "http://localhost:4000";
var api_app = "https://mumuapi.mounoydev.com";

const axiosAPIistance = Axios.create({
  baseURL: api_app,
});
axiosAPIistance.defaults.timeout = 60 * 1000 * 5;

axiosAPIistance.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers["authorization"] = token;
      config.headers["nonce"] = n();
      Store.commit("LoginSystem/auth_success", token);
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosAPIistance.interceptors.response.use(
  (response) => {
    return response;
  },
  function(error) {
    const originalRequest = error.config;
    // if (
    //   error.response.status === 401 &&
    //   originalRequest.url === "http://13.232.130.60:8081/v1/auth/token"
    // ) {
    //   router.push("/login");
    //   return Promise.reject(error);
    // }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorageService.getRefreshToken();
      return axiosAPIistance({
        method: "POST",
        url: "https://ma.mounoydev.com/v1/login/renew",
        data: {
          app: "mumu",
          uuid: localStorage.getItem("uuid"),
          rftk: refreshToken,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            localStorageService.setToken(res.data);
            axiosAPIistance.defaults.headers.common[
              "authorization"
            ] = localStorageService.getAccessToken();
            return axiosAPIistance(originalRequest);
          }
        })
        .catch((err) => {
          if (err.response.status == 403) Store.dispatch("LoginSystem/LOGOUT");
        });
    }
    if (error.response.status === 403) Store.dispatch("LoginSystem/LOGOUT");

    return Promise.reject(error);
  }
);

export const axios_app = axiosAPIistance;
