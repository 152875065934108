<template>
  <f7-page style="background:white">
    <f7-block>
      <f7-row>
        <f7-col>
          <div class="row-align">
            <img
              class="img-profile-user"
              src="/static/icons/512x512.png"
              width="45%"
              style="margin-right: -26px"
            />
            <a><f7-badge class="baf" color="orange"
              ><f7-icon material="create" style="font-size: 18px"></f7-icon
            ></f7-badge></a>
          </div>
        

        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>
<script>

export default {
  
};
</script>
<style>
.baf {
  height: 26px;
  margin-top: -26px;
}
.color-gray {
  color: #a5a3a1;
}
.pt-icon{
  padding-top:18px;
}
</style>
