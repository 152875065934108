<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
          </a>
        </div>

        <!-- navbar -->

        <div class="title nb-title">ເມນູທັງຫມົດ</div>
        <div class="right">
          <a href="/addItem/0" class="link icon-color">
            <i class="icon f7-icons">plus_circle</i>
          </a>
        </div>
      </div>
      <div class="subnavbar bg" style="padding-left: 25px; padding-right: 25px">
        <form class="searchbar">
          <div class="searchbar-inner">
            <div class="searchbar-input-wrap">
              <input type="search" placeholder="Search" />
              <i class="searchbar-icon"></i>
              <span class="input-clear-button"></span>
            </div>
            <span class="searchbar-disable-button if-not-aurora">Cancel</span>
          </div>
        </form>
      </div>
    </div>
    <!-- navbar -->

    <!-- card -->

    <div class="page-content">
      <br />
      <div class="row">
        <div class="col-20"></div>
        <div class="col-40">
          <div class="list no-hairlines-between">
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap input-dropdown-wrap textfield">
                  <select v-model="catselected" placeholder="Please choose...">
                    <option
                      v-for="d in catdata"
                      :key="d.cid"
                      v-bind:value="d.cid"
                    >
                      {{ d.name }}
                    </option>
                  </select>
                </div>
              </div>
            </li>
          </div>
        </div>
        <div class="col-20"></div>
      </div>
      <!-- <div class="block list-title">ເມນູ</div> -->
      <div class="list inset media-list">
        <ul>
          <div v-for="d in product" :key="d.pid">
            <li>
              <div class="row">
                <div class="col-90">
                  <a @click="EditItem(d)" class="item-link item-content">
                    <div class="item-media">
                      <img :src="picurl(d.pic) || picture" width="40" />
                    </div>
                    <div class="item-inner">
                      <div class="item-title-row fore-item">
                        <div class="item-title fonts-menu-name">
                          {{ d.name }}
                        </div>
                        <div class="item-after fonts-menu-name">
                          {{ (d.price || 0).toLocaleString() }}
                        </div>
                      </div>
                      <div class="item-subtitle fonts-menu-price">
                        {{ d.barcode }}
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-10 center" style="margin-top: 25px">
                  <a @click="deleteItem(d)">
                    <i class="f7-icons">xmark</i>
                  </a>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
// import Menu from "../components/menu.vue";
import { axios_app } from "@/js/axios-app";

export default {
  data() {
    return {
      catdata: null,
      catselected: null,
      product: null,
      picture:
        "https://ms.mounoydev.com/mumu/e00620fc-a8a2-4cb0-ae88-a88d9bcbde74.webp",
    };
  },
  created() {
    this.init();
  },
  watch: {
    catselected: function (val) {
      this.showpro(val);
    },
  },
  methods: {
    deleteImgInStore(pic_name) {
      // console.log(pic_name);
      axios_app({
        method: "POST",
        url: "https://ms.mounoydev.com/remove",
        data: {
          name: pic_name,
        },
      })
        .then((resp) => {
          console.log("delete from store");
        })
        .catch((err) => {
          // reject(err);
        });
    },
    deleteItem(id) {
      var _this = this;

      this.$f7.dialog.confirm(
        "Are you sure to Delete this item ",
        async function () {
          //   this.$f7.dialog.alert("Ok, your name is " + name);
          await _this.deleteImgInStore(id.pic);
          axios_app({
            method: "DELETE",
            url: "/v1/mumu/pro/" + id.proid,
          })
            .then((resp) => {
              if (resp.status == 200) {
                console.log("delete success");

                _this.showpro(id.cid);
              }
            })
            .catch((err) => {
              // reject(err);
            });
        }
      );
    },
    EditItem(pro) {
      this.$f7router.navigate("/addItem/" + pro.pid, {
        props: {
          product: pro,
        },
      });
    },
    init: function () {
      axios_app({
        method: "GET",
        url: "/v1/mumu/cat",
        data: {},
      })
        .then((resp) => {
          this.catdata = resp.data;
          this.catselected = this.catdata.length ? this.catdata[0].cid : "";
        })
        .catch((err) => {
          // reject(err);
        });
    },
    picurl: function (file) {
      var pic = null;
      if (file == null) {
        pic = null;
      } else {
        console.log('in')
        var url = "https://ms.mounoydev.com/mumu/" + file;
        pic = url;
      }
      return url;
    },
    showpro: function (id) {
      axios_app({
        method: "GET",
        url: "/v1/mumu/pro/" + id,
        data: {},
      })
        .then((resp) => {
          this.product = resp.data;
        })
        .catch((err) => {
          // reject(err);
        });
    },
  },
};
</script>
<style scoped>
.center {
  align-items: center;
  text-align: center;
}
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}

.textfield-round {
  border: 2px solid #ffa9ad;
  border-radius: 100px;
  padding: 20px;
}

.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}

@media screen and (min-width: 300px) {
  .fonts-menu-name {
    font-size: 15px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 13px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  img {
    width: 70px;
    height: 70px;
  }
}

@media screen and (min-width: 800px) {
  .fonts-menu-name {
    font-size: 24px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  img {
    width: 100px;
    height: 100px;
  }
}
</style>

