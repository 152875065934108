<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
          </a>
        </div>

        <!-- navbar -->

        <div class="title nb-title">ຈັດການຂໍ້ມູນຮ້ານ</div>
      </div>
    </div>

    <!-- navbar -->
    <div class="login-screen-content" style="margin-top:30px">
      <div class="logo img center">
        <img src="/static/icons/512x512.png" class="logo" style="width:150px; padding-top:50px;" />
        <a>
          <f7-badge class="baf" color="orange">
            <f7-icon material="create" style="font-size: 18px"></f7-icon>
          </f7-badge>
        </a>
      </div>

      <form>
        <div class="list no-hairlines-between">
          <ul>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="text" name="username" placeholder="ຊື່ຮ້ານ" />
                </div>
              </div>
            </li>

            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="text" name="username" placeholder="ເບີໂທຕິດຕໍ່" />
                </div>
              </div>
            </li>
            <li>
              <div class="row" style="padding-left: 23px;">
                <div class="col-33">
                  <div class="item-inner">
                    <div class="item-input-wrap input-dropdown-wrap textfield">
                      <select placeholder="Please choose...">
                        <option value hidden>ແຂວງ</option>
                        <option value="Male">ຈັນທະບູລີ</option>
                        <option value="Female">ໄຂທານີ</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-33">
                  <div class="item-inner">
                    <div class="item-input-wrap input-dropdown-wrap textfield">
                      <select placeholder="Please choose...">
                        <option value hidden>ເມືອງ</option>
                        <option value="Male">ຈັນທະບູລີ</option>
                        <option value="Female">ໄຂທານີ</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-33">
                  <div class="item-inner">
                    <div class="item-input-wrap input-dropdown-wrap textfield">
                      <select placeholder="Please choose...">
                        <option value hidden>ບ້ານ</option>
                        <option value="Male">ຈັນທະບູລີ</option>
                        <option value="Female">ໄຂທານີ</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap input-dropdown-wrap textfield">
                  <select placeholder="Please choose...">
                    <option value hidden>ສະກຸນເງິນ</option>
                    <option value="Male">ຈັນທະບູລີ</option>
                    <option value="Female">ໄຂທານີ</option>
                  </select>
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap input-dropdown-wrap textfield">
                  <select placeholder="Please choose...">
                    <option value="Male">ປະເພດຮ້ານ</option>
                    <option value="Female">ຊາຍ</option>
                  </select>
                </div>
              </div>
            </li>
            <li>
              <div class="col button-main">
                <f7-button raised fill>ບັນທຶກ</f7-button>
              </div>
            </li>
          </ul>
        </div>
      </form>
    </div>

    <!-- card -->
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.fore-item {
  font-size: 20px;
  color: #878484;
  font-family: boonhome;
}

.card-diy {
  height: 150px;
  width: 150px;
  background-color: #ffa9ad;
}
.card-color {
  background-color: #ffa9ad;
}
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}
.logo img { 
  border-radius: 100%;
}
.textfield {
  border: 2px solid #ffa9ad;
  border-radius: 5px;
}
.textfield-round {
  border: 2px solid #ffa9ad;
  border-radius: 100px;
  padding: 20px;
}
.fonts-Bold {
  font-size: 36px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.fonts-Bold-button {
  font-size: 26px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}
.fonts-book {
  color: #ffa9ad;
  font-family: boonhome;
}

@media screen and (min-width: 400px) {
  .fonts-menu-name {
    font-size: 18px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 14px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
}

@media screen and (min-width: 800px) {
  .fonts-menu-name {
    font-size: 24px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
}
</style>

