<template>
  <div class="page bg">
    <!-- navbar -->
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Back</span>
          </a>
        </div>
        <div class="title nb-title">ຊຳລະເງິນ</div>
        <div class="right">
          <a class="link icon-color">
            <i class="icon f7-icons"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- navbar -->
    <div class="page-content">
      <div class="login-screen-content">
        <div style="height: 30px"></div>
        <div class="login-screen-title fonts-Bold-button shadow">
          ລາຄາລວມທັງໝົດ
        </div>

        <div class="login-screen-title fonts-Bold shadow">
          {{ Number(bill.totol).toLocaleString() }}
        </div>
        <div>
          <div class="list accordion-list accordion-opposite">
            <ul>
              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-input-wrap textfield">
                    <input
                      type="number"
                      v-model="valget"
                      placeholder="ເງິນທີ່ໄດ້ຮັບ"
                    />
                  </div>
                </div>
              </li>

              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-input-wrap textfield">
                    <input
                      disabled
                      type="number"
                      v-model="changemoney"
                      placeholder="ເງີນທອນ"
                    />
                  </div>
                </div>
              </li>
              <li v-if="false" class="accordion-item">
                <a class="item-content item-link" href="#">
                  <div class="item-inner">
                    <div class="item-title fonts-book">ສະກຸນເງິນອື່ນ</div>
                  </div>
                </a>
                <div class="accordion-item-content">
                  <div class="block no-hairlines-between">
                    <a
                      href="#"
                      class="item-content swipeout-content item-content"
                    >
                      <div class="item-media">
                        <i class="icon icon-f7"></i>
                      </div>
                      <div class="item-inner item-style">
                        <div class="item-title">ກີບ</div>

                        <div class="item-after" style="font-weight: bold">
                          10.000 K
                        </div>
                      </div>
                    </a>
                    <a
                      href="#"
                      class="item-content swipeout-content item-content"
                    >
                      <div class="item-media">
                        <i class="icon icon-f7"></i>
                      </div>
                      <div class="item-inner item-style">
                        <div class="item-title" style="font-weight: bold">
                          ບາດ
                        </div>

                        <div class="item-after" style="font-weight: bold">
                          10.000 K
                        </div>
                      </div>
                    </a>
                    <a
                      href="#"
                      class="item-content swipeout-content item-content"
                    >
                      <div class="item-media">
                        <i class="icon icon-f7"></i>
                      </div>
                      <div class="item-inner item-style">
                        <div class="item-title">ໂດລາ</div>

                        <div class="item-after">10.000 K</div>
                      </div>
                    </a>
                    <div class="item-inner">
                      <div class="item-input-wrap textfield">
                        <input type="text" name="username" placeholder="₭" />
                      </div>
                    </div>
                    <div class="item-inner">
                      <div class="item-input-wrap textfield">
                        <input type="text" name="username" placeholder="$" />
                      </div>
                    </div>
                    <div class="item-inner">
                      <div class="item-input-wrap textfield">
                        <input type="text" name="username" placeholder="฿" />
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="block-footer">
            <div class="col button-main">
              <f7-button icon-f7="money_dollar_circle" raised fill @click="pay"
                >ຊຳລະເງິນ</f7-button
              >
            </div>
            <br />
            <f7-button
              icon-f7="printer"
              class="bg fonts-book"
              raised
              fill
              @click="pcprintbill"
              >ຊຳລະເງິນ ແລະ ພິມບິນ</f7-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- card -->
    <div style="display: none">
      <div id="bill">
        <div class="printing">
          <div class="invoice-title">
            <img
              v-if="shopinfo.logo != '' || shopinfo.logo != null"
              :src="shopinfo.logo"
              style="width: 3cm"
            />
            <div id="main-title">
              <h4 class="mmm" style="font-size: 20px">ໃບບິນ</h4>
              <span class="fl12">#{{ bill.tsid }}</span>
            </div>
            <span class="fl12">ວັນທີ: {{ bill.date }}</span>
            <br />
            <span class="fl12">ເບີໂທ:{{ shopinfo.tel }}</span>
            <!-- <br /> -->
            <!-- <span class="fontlao fl12">ສາຂາ:{{brname}}</span> -->
          </div>
          <span>---------------------</span>
          <div>
            <table>
              <thead>
                <tr>
                  <td class="fl12 priceqt">ລາຍການ</td>
                  <td class="fl12 amount">ລາຄາ</td>
                </tr>
              </thead>
              <thead class="fl12">
                <template v-for="(d, k) in bill.list">
                  <tr>
                    <td class="fl12" colspan="2" style="font-weight: bold">
                      {{ d.name }}
                    </td>
                  </tr>
                  <tr>
                    <td class="fl12" colspan="2">{{ d.barcode }}</td>
                  </tr>
                  <tr>
                    <td class="fl12 priceqt">{{ d.price }} ກີບ x {{ d.qty }}</td>
                    <td class="fl12 amount" style="font-weight: bold">
                      {{ d.amount.toLocaleString() }} ກີບ
                    </td>
                  </tr>
                </template>
              </thead>
            </table>
            <span>---------------------</span>
          </div>
        </div>
        <div class="center">
          <span style="font-size: 15pt"
            >ລວມ: {{ Number(bill.totol).toLocaleString() }} ກີບ</span
          >
          <br />
          <span class="fl12">ຂອບໃຈທີ່ໃຊ້ບໍລິການ</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { axios_app } from "@/js/axios-app";
import Printd from "printd";
export default {
  computed: {
    ...mapGetters("shop", ["bill", "shopinfo"]),
    changemoney() {
      return (this.change = this.valget - Number(this.bill.totol));
    },
  },

  data() {
    return {
      valget: null,
      change: "",
    };
  },
  methods: {
    async pay() {
      await axios_app({
        method: "POST",
        url: "/v1/mumu/pay",
        data: { tsid: this.bill.tsid },
      })
        .then((resp) => {
          this.$f7router.navigate("/table/");
        })
        .catch((err) => {});
    },
    pcprintbill() {
      const cssText = `
      @font-face {
  font-family: boonhome;
  src: url(../fonts/BoonHome-400.ttf);
  font-weight: normal;
  font-style: normal;

}
      *{ 
        font-family: boonhome !important; 
      }
      .fl12{
        font-size:12px;
      }
      .priceqt{
        width:20mm;
      }
      .amount{
        width:18mm;
        text-align: right  !important;
      }
      `;
      var d = new Printd();
      d.print(document.getElementById("bill"), [cssText]);
      this.pay();
    },
  },
};
</script>
<style scoped>
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}

.fonts-Bold {
  font-size: 50px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.fonts-Bold-button {
  font-size: 26px;
  color: #ffa9ad;
  font-weight: regular;
  font-family: boonhome;
}
.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}
.fonts-book {
  color: #ffa9ad;
  font-family: boonhome;
}
</style>

