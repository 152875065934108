<template>
  <div class="page bg">
    <!-- navbar -->
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
            <span class="if-not-md">Back</span>
          </a>
        </div>
        <div class="title nb-title">ບີນທີ: 01</div>
        <div class="right">
          <a class="link icon-color">
            <i class="icon f7-icons"></i>
          </a>
        </div>
      </div>
    </div>
    <!-- navbar -->
    <div class="page-content">
      <form>
        <div class="list" style="padding: 20px">
          <f7-col>
            <f7-row>
              <div class="item-title fonts-menu-name">
                ຜູ້ຂາຍ: {{ mydata.uid }}
              </div>
              <div class="item-title fonts-menu-name">
                ວັນທີ: {{ this.format_date(mydata.date) }}
              </div>
            </f7-row>
            <f7-row>
              <div class="item-title fonts-menu-name">
                ໂຕະ: {{ mydata.tb_name }}
              </div>
              <!-- <div class="item-title fonts-menu-name">ເວລາ: {{this.format_date(mydata.date)}}</div> -->
            </f7-row>
          </f7-col>
        </div>
      </form>
      <div class="block list-title">ລາຄາລວມ: {{ mydata.totol | tls }} ₭</div>
      <div class="list center">
        <ul>
          <div v-for="(d, i) in mydata.list" :key="i">
            <a href="#" class="item-content swipeout-content item-content">
              <div class="item-media">
                <i class="icon icon-f7"></i>
              </div>
              <div class="item-inner item-style item-cell">
                <div class="item-row">
                  <div class="item-cell">
                    <div style="font-weight: bold">{{ d.name }}</div>
                    <div>{{ d.barcode }}</div>
                  </div>

                  <div class="item-cell">{{ d.qty }} x {{ d.price | tls }}</div>
                  <div style="font-weight: bold" class="item-cell">
                    {{ d.amount | tls }} ₭
                  </div>
                </div>
              </div>
            </a>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <!-- card -->
</template>
<script>
import { axios_app } from "@/js/axios-app";
import moment from "moment";
export default {
  computed: {
    // ...mapGetters("shop", ["bill", "shopinfo"]),
    changemoney() {
      return (this.change = this.valget - Number(this.bill.totol));
    },
  },
  created() {
    this.viewdetails(this.$f7route.params.tsid);
    // this.viewdetails('336');
  },
  data() {
    return {
      valget: null,
      mydata: "",
    };
  },
  methods: {
    async viewdetails(id) {
      this.$f7route.params.tsid;
      await axios_app({
        method: "GET",
        url: "/v1/mumu/transaction/" + id,
        data: {},
      })
        .then((res) => {
          this.mydata = res.data;
          //  this.$f7router.navigate("/table/");
        })
        .catch((err) => {});
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
  },
};
</script>

<style scoped>
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}

@media screen and (min-width: 300px) {
  .fonts-menu-name {
    font-size: 18px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 14px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
}

@media screen and (min-width: 800px) {
  .fonts-menu-name {
    font-size: 24px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
}
</style>

