import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import LoginSystem from "./modules/login-system";
import locat from "./modules/location";
import shop from "./modules/shop";
import user from "./modules/user";
import a from "axios";

export default new Vuex.Store({
  state: { // Data
    counter: 0,
    // order:{}
  },
  mutations: { //Call by Commit /// Call by
    p(s, data) {
      s.counter += data
    },
    m(s, data) {
      s.counter -= data
    },
    // SET_ORDERS(state,data){
    //   state.order = data
    // } 
  },
  actions: { // Call by dispatch // Like Metho //Axios 
    m({ commit }) {
      a("https://www.random.org/integers/?num=1&min=1&max=6&col=1&base=10&format=plain&rnd=new").then(r => {
        commit('m', r.data) // To mutations
      })
    },
    p({ commit }) {
      a("https://www.random.org/integers/?num=1&min=1&max=6&col=1&base=10&format=plain&rnd=new").then(r => {
        commit('p', r.data) // To mutations
      })
    }
  },
  getters: {
    counterSq(state) {
      return state.counter * state.counter

    }

  },
  modules: {
    LoginSystem,
    locat,
    user,
    shop,
  },
});
