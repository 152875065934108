import Vue from "vue";
import { axios_app } from "@/js/axios-app";
export default {
  namespaced: true,
  state: {
    stop: {},
    tables: {},
    menus: {},
    cats: {},
    order: {},
    sendorder: {},
    bill: {},
  },
  mutations: {
    success(state, data) {
      // state.status = "success";
      state.tables = data;
    },
    clear(state) {
      state.tables = {};
    },
    m_menus(state, data) {
      state.menus = data;
    },
    m_cats(state, data) {
      state.cats = data;
    },
    m_order(state, data) {
      state.order[data.pid] = data;
    },
    m_sendorder(state, data) {
      state.sendorder[data.pid] = data;
    },
    m_clear_mydorder(state) {
      state.sendorder = {};
      state.order = {};
    },
    m_delete_item(state, data) {
      // state.sendorder[data.pid] = {};
      // state.order[data.pid] = {};
      delete state.sendorder[data.pid];
      delete state.order[data.pid];
      Vue.set(state.order, data.pid);
      Vue.set(state.sendorder, data.pid);

      // state.sendorder = {};
      // state.order = {};
    },
    m_bill(state, data) {
      state.bill = data;
    },
    m_shop(state, data) {
      state.shop = data;
    },
  },
  actions: {
    GET_TABLE({ commit }) {
      axios_app({
        method: "GET",
        url: "/v1/mumu/tables",
        data: {},
      })
        .then((resp) => {
          commit("success", resp.data);
          // resolve(resp);
        })
        .catch((err) => {
          // commit("error", err);
          // reject(err);
        });
    },
    A_CATS({ commit }) {
      axios_app({
        method: "GET",
        url: "/v1/mumu/cat",
        data: {},
      })
        .then((resp) => {
          commit("m_cats", resp.data);
          // resolve(resp);
        })
        .catch((err) => {
          // commit("error", err);
          // reject(err);
        });
    },
    A_MENU({ commit }) {
      axios_app({
        method: "GET",
        url: "/v1/mumu/pro",
        data: {},
      })
        .then((resp) => {
          var tem = resp.data;
          var outObject = tem.reduce(function(a, e) {
            // GROUP BY estimated key (estKey), well, may be a just plain key
            // a -- Accumulator result object
            // e -- sequentally checked Element, the Element that is tested just at this itaration
            // new grouping name may be calculated, but must be based on real value of real field
            let estKey = e["cid"];
            (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
            return a;
          }, {});
          commit("m_menus", outObject);
          // resolve(resp);
        })
        .catch((err) => {
          // commit("error", err);
          // reject(err);
        });
    },
    A_ADD_ORDER({ commit }, value) {
      console.log("value" + JSON.stringify(value));
      commit("m_order", value);
      commit("m_sendorder", { qty: value.qty, pid: value.pid });
    },
    A_DELETE_ORDER({ commit }, value) {
      // console.log('order' + JSON.stringify(this.state.order), 'send' + JSON.stringify(this.state.sendorder));
      commit("m_delete_item", { pid: value.pid });
    },
    A_SEND_ORDER({ commit }, mydata) {
      axios_app({
        method: "POST",
        url: "/v1/mumu/addorders",
        data: mydata,
      })
        .then((resp) => {
          commit("m_clear_mydorder");
        })
        .catch((err) => {});
    },
    A_GET_BILL({ commit }, mydata) {
      axios_app({
        method: "POST",
        url: "/v1/mumu/getbill",
        data: mydata,
      })
        .then((resp) => {
          commit("m_bill", resp.data);
        })
        .catch((err) => {});
    },
    A_SEND_ORDER_BILL({ commit }, mydata) {
      axios_app({
        method: "POST",
        url: "/v1/mumu/addorders",
        data: mydata,
      })
        .then((resp) => {
          commit("m_clear_mydorder");

          axios_app({
            method: "POST",
            url: "/v1/mumu/getbill",
            data: mydata,
          })
            .then((resp) => {
              commit("m_bill", resp.data);
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    },
    A_SEND_ORDER_byBarcode_BILL({ commit }, mydata) {
      return new Promise((resolve, reject) => {
        axios_app({
          method: "POST",
          url: "/v1/mumu/addorders_barcode",
          data: mydata,
        })
          .then((resp) => {
            if (resp.status == 209) {
              resolve(resp);
            } else {
              commit("m_clear_mydorder");
              axios_app({
                method: "POST",
                url: "/v1/mumu/getbill",
                data: mydata,
              })
                .then((resp) => {
                  commit("m_bill", resp.data);
                })
                .catch((err) => {});
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    A_GET_SHOP({ commit }, mydata) {
      axios_app({
        method: "GET",
        url: "/v1/mumu/shopinfo",
        data: mydata,
      })
        .then((resp) => {
          commit("m_shop", resp.data);
        })
        .catch((err) => {});
    },
    A_Delete_ORDER_BILL({ commit }, mydata) {
      axios_app({
        method: "DELETE",
        url: `/v1/mumu/tsdetail/${mydata.proid}/${mydata.tsid}`,
      })
        .then((resp) => {
          commit("m_clear_mydorder");
          axios_app({
            method: "POST",
            url: "/v1/mumu/getbill",
            data: mydata,
          })
            .then((resp) => {
              commit("m_bill", resp.data);
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    },
  },
  getters: {
    showtables: (state) => state.tables,
    showmenus: (state) => state.menus,
    showcats: (state) => state.cats,
    showorders: (state) => state.order,
    getsendorder: (state) => state.sendorder,
    bill: (state) => state.bill,
    shopinfo: (state) => state.shop,
  },
};
