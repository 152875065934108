<template>
  <div class="page bg">
    <div class="navbar">
      <div class="navbar-bg bg"></div>
      <div class="navbar-inner">
        <div class="left">
          <a class="link back icon-color">
            <i class="icon icon-back"></i>
          </a>
        </div>

        <!-- navbar -->

        <div class="title fonts-Bold-button">ເພີ່ມສ່ວນຫຼຸດໃໝ່</div>
        <div class="right">
          <a class="link back fonts-book">ບັນທຶກ</a>
        </div>
      </div>
    </div>
    <br />
    <!-- navbar -->
    <br />

    <div class="login-screen-content">
      <form>
        <div class="list no-hairlines-between">
          <ul>
            <!-- <li>
              
              <f7-row>
                <f7-col>
                  <label
                    class="item-checkbox item-content"
                    style="font-family: boonhome; padding-top:10px;"
                  >
                    <input type="checkbox" name="demo-checkbox" value="Books" checked="checked" />
                    <i class="icon icon-checkbox"></i>
                    <div class="item-inner">
                      <div class="item-title">%</div>
                    </div>
                  </label>
                </f7-col>
                <f7-col>
                  <label
                    class="item-checkbox item-content"
                    style="font-family: boonhome; padding-top:10px;"
                  >
                    <input type="checkbox" name="demo-checkbox" value="Books" checked="checked" />
                    <i class="icon icon-checkbox"></i>
                    <div class="item-inner">
                      <div class="item-title">ລາຄາ</div>
                    </div>
                  </label>
                </f7-col>
              </f7-row>
            </li>-->
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap input-dropdown-wrap textfield">
                  <select placeholder="Please choose...">
                    <option value="Male">ເລືອກຮູບແບບສ່ວນຫຼຸດ</option>
                    <option value="Female">%</option>
                    <option value="Female">ລາຄາ</option>
                  </select>
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="text" name="username" placeholder="ຊື່ສ່ວນຫຼຸດ" />
                </div>
              </div>
            </li>
            <li class="item-content item-input">
              <div class="item-inner">
                <div class="item-input-wrap textfield">
                  <input type="text" name="username" placeholder="ຈຳນວນ" />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </form>
    </div>
    <!-- card -->
  </div>
</template>
<script>
export default {
};
</script>
<style scoped>
.fore-item {
  font-size: 20px;
  color: #878484;
  font-family: boonhome;
}

.card-diy {
  height: 150px;
  width: 150px;
  background-color: #ffa9ad;
}
.card-color {
  background-color: #ffa9ad;
}
.icon-color {
  color: #ffa9ad;
}

.bg {
  background-color: #ffff;
}
.center {
  align-items: center;
  text-align: center;
}
.logo img {
  border-radius: 100%;
}
.textfield {
  border: 2px solid #ffa9ad;
  border-radius: 5px;
}
.textfield-round {
  border: 2px solid #ffa9ad;
  border-radius: 100px;
  padding: 20px;
}
.fonts-Bold {
  font-size: 36px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.fonts-Bold-button {
  font-size: 26px;
  color: #ffa9ad;
  font-weight: bold;
  font-family: boonhome;
}
.shadow {
  text-shadow: 2px 2px 4px #0000004d;
}
.fonts-book {
  color: #ffa9ad;
  font-family: boonhome;
}
@media screen and (min-width: 400px) {
  .fonts-menu-name {
    font-size: 18px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 14px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
}

@media screen and (min-width: 800px) {
  .fonts-menu-name {
    font-size: 24px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
  .fonts-menu-price {
    font-size: 20px;
    color: #3c3c3c;
    font-weight: bold;
    font-family: boonhome;
  }
}
</style>

